import { Theme, createStyles } from '@material-ui/core';

const styles = ({ breakpoints, palette }: Theme) =>
  createStyles({
    navButton: {
      display: 'none',
      textAlign: 'center',

      '&:hover': {
        background: palette.primary.main,
        color: palette.common.white,
        transition: 'all 0.3s ease-in-out',
      },

      [breakpoints.up('md')]: {
        display: 'inline-block',
      },
    },
  });

export default styles;
