import { Theme, createStyles } from '@material-ui/core';

import { variables } from 'Theme/variables';

const styles = ({ spacing, breakpoints }: Theme) =>
  createStyles({
    textContainer: {
      textAlign: 'justify',
      marginTop: spacing(4),

      [breakpoints.up('sm')]: {
        marginTop: '10%',
      },

      [breakpoints.up('md')]: {
        marginTop: 0,
      },

      [`@media screen and (min-width: ${variables.breakpoints.desktop_1500}px)`]: {
        marginTop: spacing(6.3),
      },

      [`@media screen and (min-width: ${variables.breakpoints.desktop_1780}px)`]: {
        marginTop: spacing(10.6),
      },
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',

      [`@media screen and (min-width: ${variables.breakpoints.desktop_768}px)`]: {
        justifyContent: 'unset',
      },
    },
    button: {
      marginTop: spacing(4),
      height: 45,
      padding: spacing(1),
      minWidth: 100,
    },
    buttonItem: {
      [breakpoints.up('md')]: {
        marginRight: spacing(2),
      },

      [`@media screen and (min-width: ${variables.breakpoints.desktop_1260}px)`]: {
        marginRight: spacing(1),
      },
    },
    exploreButton: {
      display: 'flex',
      justifyContent: 'flex-end',

      [`@media screen and (min-width: ${variables.breakpoints.desktop_768}px)`]: {
        marginLeft: spacing(3),
      },

      [breakpoints.up('md')]: {
        marginLeft: 0,
      },

      [`@media screen and (min-width: ${variables.breakpoints.desktop_1260}px)`]: {
        justifyContent: 'flex-start',
      },
    },
    link: {
      textDecoration: 'none',
      width: '100%',
      display: 'contents',
    },
    avatarSVGGridItem: {
      [breakpoints.up('md')]: {
        order: 3,
      },
    },
    textGridItem: {
      [breakpoints.up('md')]: {
        order: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        paddingRight: spacing(4),
        marginTop: spacing(4),
      },

      [`@media screen and (min-width: ${variables.breakpoints.desktop_1260}px)`]: {
        justifyContent: 'flex-start',
        paddingTop: 10 * spacing(1),
      },
    },
    socialMediaIconsGridItem: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',

      [breakpoints.up('md')]: {
        marginTop: spacing(6),
        justifyContent: 'flex-start',
      },

      [breakpoints.up('lg')]: {
        marginTop: '18vh',
      },

      [breakpoints.up('xl')]: {
        marginTop: '26vh',
      },

      [`@media screen and (min-width: 2560px)`]: {
        marginTop: '20vh',
      },
    },
    triangle: {
      display: 'none',

      [breakpoints.up('sm')]: {
        transform: ' scale(0.8) translate(-134px, -95px)',
        display: 'block',
      },

      [breakpoints.up('md')]: {
        transform: 'translate(-134px, -174px)',
      },

      [breakpoints.up('lg')]: {
        transform: 'translate(-134px, -98px)',
      },

      [`@media screen and (min-width: ${variables.breakpoints.desktop_2560}px)`]: {
        transform: 'translate(-134px, -11px)',
      },
    },
    secondTriangle: {
      transform: 'translate(10px, 110px)',
    },
    circle: {
      transform: 'scale(0.5) translate(120vw, 60vh)',

      [breakpoints.up('sm')]: {
        transform: 'scale(0.5) translate(0vw, 50vh)',
      },
    },
    text: {
      maxWidth: 720,
    },
  });

export default styles;
