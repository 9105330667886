import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    triangle: {
      display: 'block',
      position: 'absolute',
    },
  });

export default styles;
