import { IVariables } from './types';

export const variables: IVariables = {
  breakpoints: {
    desktop_375: '375',
    desktop_400: '400',
    desktop_500: '500',
    desktop_600: '600',
    desktop_700: '700',
    desktop_768: '768',
    desktop_1260: '1260',
    desktop_1500: '1500',
    desktop_1780: '1780',
    desktop_1920: '1920',
    desktop_2048: '2048',
    desktop_2560: '2560',
  },

  rotate: 'rotate(75deg)',
  scale: 'scale(0.7)',
};
