import { Theme, createStyles } from '@material-ui/core';

const styles = ({ spacing, palette, breakpoints }: Theme) =>
  createStyles({
    section: {
      display: 'flex',
      textAlign: 'justify',
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: spacing(8),
      backgroundColor: palette.secondary.light,
      minHeight: 'unset',
    },
    container: {
      display: 'flex',
      justifyContent: 'space-around',
    },
    logoContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: spacing(2),
      marginBottom: spacing(2),

      [breakpoints.up('md')]: {
        marginTop: 0,
        marginBottom: spacing(1),
      },
    },
    text: {
      marginBottom: spacing(2),
      textAlign: 'center',
      maxWidth: 720,

      [breakpoints.up('md')]: {
        marginBottom: 0,
        textAlign: 'left',
      },

      [breakpoints.up('xl')]: {
        maxWidth: 'unset',
        textAlign: 'center',
      },
    },
    list: {
      listStyle: 'none',
      textAlign: 'center',
      padding: 0,
      marginTop: 0,

      [breakpoints.up('md')]: {
        textAlign: 'left',
      },

      [breakpoints.up('xl')]: {
        textAlign: 'center',
      },
    },
    listItem: {
      [breakpoints.up('md')]: {
        marginTop: spacing(1),
      },
    },
    title: {
      color: palette.primary.main,
      textTransform: 'uppercase',
    },
    socialmediaIconsTitle: {
      [breakpoints.up('md')]: {
        marginLeft: -1 * spacing(1),
      },
      [breakpoints.up('xl')]: {
        marginLeft: 0,
      },
    },
    socialmediaIcons: {
      width: 40,

      '&:hover': {
        backgroundColor: 'transparent',
        color: palette.primary.dark,
      },
    },
    items: {
      [breakpoints.up('md')]: {
        display: 'flex',
        marginTop: '5vh',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
    },
    grid: {
      [breakpoints.up('md')]: {
        padding: spacing(2),
      },
    },
    fab: {
      height: 50,
      width: 50,
      border: '2px solid white',
      borderRadius: 5,
      backgroundColor: palette.primary.main,

      '&:hover': {
        backgroundColor: palette.primary.dark,
      },
    },
    extendedIcon: {
      color: palette.common.white,
    },
    fabGridItem: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    copyright: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'left',
      color: palette.primary.main,
    },
    link: {
      color: palette.common.black,
      display: 'contents',
    },
    rightGrid: {
      [breakpoints.up('xl')]: {
        maxWidth: 1000,
      },
    },
  });

export default styles;
