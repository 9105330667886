import React from 'react';

import { ContactSection } from 'Components/ContactSection';

class Contact extends React.Component {
  public render() {
    return (
      <ContactSection />
    );
  }
}

export default Contact;
