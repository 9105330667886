import React from 'react';

import { HomeSection } from 'Components/HomeSection';

class Home extends React.Component {
  public render() {
    return (
      <HomeSection />
    );
  }
}

export default Home;
