import { Theme, createStyles } from '@material-ui/core';

import { variables } from 'Theme/variables';

const styles = ({ spacing, breakpoints, palette }: Theme) =>
  createStyles({
    appbar: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    menuContainer: {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    menuButton: {
      marginRight: -10,
      height: 50,
      borderRadius: 0,

      [breakpoints.up('md')]: {
        display: 'none',
      },
    },
    logoContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    logoLink: {
      display: 'contents',
    },
    link: {
      color: palette.primary.dark,
      textDecoration: 'none',
    },
    icon: {
      color: palette.primary.dark,
      fontSize: 20,
    },
    list: {
      [breakpoints.up('md')]: {
        display: 'none',
      },
    },
    listItem: {
      paddingRight: spacing(4),
    },
    listItemIcon: {
      minWidth: 40,
    },
    selected: {
      backgroundColor: `${palette.secondary.light} !important`,
    },
    triangle: {
      transform: `${variables.rotate} translate(-30%, -73%)`,

      [breakpoints.up('sm')]: {
        transform: `${variables.rotate} translate(2%, -44vh)`,
      },
      [breakpoints.up('md')]: {
        transform: `${variables.rotate} translate(71%, -98vh)`,
      },
      [breakpoints.up('xl')]: {
        transform: `${variables.rotate} translate(100%, -70vh)`,
      },
    },
    navigationItem: {
      paddingTop: spacing(3),
    },
  });

export default styles;
