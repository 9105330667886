import React from 'react';

interface IProps {
  className?: string;
}

const RectangleSVG = (props: IProps) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="94"
      height="346"
      viewBox="0 0 94 346"
    >
      <g
        id="Group_325"
        data-name="Group 325"
        transform="translate(-12761 -1828.188)"
      >
        <rect
          id="Rectangle_86"
          data-name="Rectangle 86"
          width="6"
          height="6"
          transform="translate(12849 1828.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_87"
          data-name="Rectangle 87"
          width="6"
          height="6"
          transform="translate(12827 1828.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_88"
          data-name="Rectangle 88"
          width="6"
          height="6"
          transform="translate(12805 1828.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_89"
          data-name="Rectangle 89"
          width="6"
          height="6"
          transform="translate(12783 1828.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_90"
          data-name="Rectangle 90"
          width="6"
          height="6"
          transform="translate(12761 1828.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_86-2"
          data-name="Rectangle 86"
          width="6"
          height="6"
          transform="translate(12849 1917.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_87-2"
          data-name="Rectangle 87"
          width="6"
          height="6"
          transform="translate(12827 1917.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_88-2"
          data-name="Rectangle 88"
          width="6"
          height="6"
          transform="translate(12805 1917.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_89-2"
          data-name="Rectangle 89"
          width="6"
          height="6"
          transform="translate(12783 1917.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_90-2"
          data-name="Rectangle 90"
          width="6"
          height="6"
          transform="translate(12761 1917.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_86-3"
          data-name="Rectangle 86"
          width="6"
          height="5"
          transform="translate(12849 2007.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_87-3"
          data-name="Rectangle 87"
          width="6"
          height="5"
          transform="translate(12827 2007.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_88-3"
          data-name="Rectangle 88"
          width="6"
          height="5"
          transform="translate(12805 2007.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_89-3"
          data-name="Rectangle 89"
          width="6"
          height="5"
          transform="translate(12783 2007.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_90-3"
          data-name="Rectangle 90"
          width="6"
          height="5"
          transform="translate(12761 2007.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_86-4"
          data-name="Rectangle 86"
          width="6"
          height="5"
          transform="translate(12849 2097.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_87-4"
          data-name="Rectangle 87"
          width="6"
          height="5"
          transform="translate(12827 2097.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_88-4"
          data-name="Rectangle 88"
          width="6"
          height="5"
          transform="translate(12805 2097.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_89-4"
          data-name="Rectangle 89"
          width="6"
          height="5"
          transform="translate(12783 2097.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_90-4"
          data-name="Rectangle 90"
          width="6"
          height="5"
          transform="translate(12761 2097.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_86-5"
          data-name="Rectangle 86"
          width="6"
          height="5"
          transform="translate(12849 1846.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_87-5"
          data-name="Rectangle 87"
          width="6"
          height="5"
          transform="translate(12827 1846.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_88-5"
          data-name="Rectangle 88"
          width="6"
          height="5"
          transform="translate(12805 1846.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_89-5"
          data-name="Rectangle 89"
          width="6"
          height="5"
          transform="translate(12783 1846.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_90-5"
          data-name="Rectangle 90"
          width="6"
          height="5"
          transform="translate(12761 1846.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_86-6"
          data-name="Rectangle 86"
          width="6"
          height="5"
          transform="translate(12849 1936.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_87-6"
          data-name="Rectangle 87"
          width="6"
          height="5"
          transform="translate(12827 1936.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_88-6"
          data-name="Rectangle 88"
          width="6"
          height="5"
          transform="translate(12805 1936.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_89-6"
          data-name="Rectangle 89"
          width="6"
          height="5"
          transform="translate(12783 1936.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_90-6"
          data-name="Rectangle 90"
          width="6"
          height="5"
          transform="translate(12761 1936.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_86-7"
          data-name="Rectangle 86"
          width="6"
          height="6"
          transform="translate(12849 2025.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_87-7"
          data-name="Rectangle 87"
          width="6"
          height="6"
          transform="translate(12827 2025.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_88-7"
          data-name="Rectangle 88"
          width="6"
          height="6"
          transform="translate(12805 2025.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_89-7"
          data-name="Rectangle 89"
          width="6"
          height="6"
          transform="translate(12783 2025.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_90-7"
          data-name="Rectangle 90"
          width="6"
          height="6"
          transform="translate(12761 2025.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_86-8"
          data-name="Rectangle 86"
          width="6"
          height="6"
          transform="translate(12849 2114.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_87-8"
          data-name="Rectangle 87"
          width="6"
          height="6"
          transform="translate(12827 2114.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_88-8"
          data-name="Rectangle 88"
          width="6"
          height="6"
          transform="translate(12805 2114.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_89-8"
          data-name="Rectangle 89"
          width="6"
          height="6"
          transform="translate(12783 2114.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_90-8"
          data-name="Rectangle 90"
          width="6"
          height="6"
          transform="translate(12761 2114.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_86-9"
          data-name="Rectangle 86"
          width="6"
          height="6"
          transform="translate(12849 1864.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_87-9"
          data-name="Rectangle 87"
          width="6"
          height="6"
          transform="translate(12827 1864.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_88-9"
          data-name="Rectangle 88"
          width="6"
          height="6"
          transform="translate(12805 1864.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_89-9"
          data-name="Rectangle 89"
          width="6"
          height="6"
          transform="translate(12783 1864.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_90-9"
          data-name="Rectangle 90"
          width="6"
          height="6"
          transform="translate(12761 1864.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_86-10"
          data-name="Rectangle 86"
          width="6"
          height="5"
          transform="translate(12849 1954.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_87-10"
          data-name="Rectangle 87"
          width="6"
          height="5"
          transform="translate(12827 1954.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_88-10"
          data-name="Rectangle 88"
          width="6"
          height="5"
          transform="translate(12805 1954.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_89-10"
          data-name="Rectangle 89"
          width="6"
          height="5"
          transform="translate(12783 1954.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_90-10"
          data-name="Rectangle 90"
          width="6"
          height="5"
          transform="translate(12761 1954.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_86-11"
          data-name="Rectangle 86"
          width="6"
          height="5"
          transform="translate(12849 2043.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_87-11"
          data-name="Rectangle 87"
          width="6"
          height="5"
          transform="translate(12827 2043.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_88-11"
          data-name="Rectangle 88"
          width="6"
          height="5"
          transform="translate(12805 2043.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_89-11"
          data-name="Rectangle 89"
          width="6"
          height="5"
          transform="translate(12783 2043.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_90-11"
          data-name="Rectangle 90"
          width="6"
          height="5"
          transform="translate(12761 2043.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_86-12"
          data-name="Rectangle 86"
          width="6"
          height="6"
          transform="translate(12849 2132.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_87-12"
          data-name="Rectangle 87"
          width="6"
          height="6"
          transform="translate(12827 2132.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_88-12"
          data-name="Rectangle 88"
          width="6"
          height="6"
          transform="translate(12805 2132.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_89-12"
          data-name="Rectangle 89"
          width="6"
          height="6"
          transform="translate(12783 2132.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_90-12"
          data-name="Rectangle 90"
          width="6"
          height="6"
          transform="translate(12761 2132.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_86-13"
          data-name="Rectangle 86"
          width="6"
          height="6"
          transform="translate(12849 1882.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_87-13"
          data-name="Rectangle 87"
          width="6"
          height="6"
          transform="translate(12827 1882.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_88-13"
          data-name="Rectangle 88"
          width="6"
          height="6"
          transform="translate(12805 1882.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_89-13"
          data-name="Rectangle 89"
          width="6"
          height="6"
          transform="translate(12783 1882.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_90-13"
          data-name="Rectangle 90"
          width="6"
          height="6"
          transform="translate(12761 1882.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_86-14"
          data-name="Rectangle 86"
          width="6"
          height="6"
          transform="translate(12849 1971.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_87-14"
          data-name="Rectangle 87"
          width="6"
          height="6"
          transform="translate(12827 1971.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_88-14"
          data-name="Rectangle 88"
          width="6"
          height="6"
          transform="translate(12805 1971.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_89-14"
          data-name="Rectangle 89"
          width="6"
          height="6"
          transform="translate(12783 1971.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_90-14"
          data-name="Rectangle 90"
          width="6"
          height="6"
          transform="translate(12761 1971.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_86-15"
          data-name="Rectangle 86"
          width="6"
          height="5"
          transform="translate(12849 2061.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_87-15"
          data-name="Rectangle 87"
          width="6"
          height="5"
          transform="translate(12827 2061.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_88-15"
          data-name="Rectangle 88"
          width="6"
          height="5"
          transform="translate(12805 2061.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_89-15"
          data-name="Rectangle 89"
          width="6"
          height="5"
          transform="translate(12783 2061.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_90-15"
          data-name="Rectangle 90"
          width="6"
          height="5"
          transform="translate(12761 2061.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_86-16"
          data-name="Rectangle 86"
          width="6"
          height="5"
          transform="translate(12849 2151.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_87-16"
          data-name="Rectangle 87"
          width="6"
          height="5"
          transform="translate(12827 2151.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_88-16"
          data-name="Rectangle 88"
          width="6"
          height="5"
          transform="translate(12805 2151.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_89-16"
          data-name="Rectangle 89"
          width="6"
          height="5"
          transform="translate(12783 2151.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_90-16"
          data-name="Rectangle 90"
          width="6"
          height="5"
          transform="translate(12761 2151.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_86-17"
          data-name="Rectangle 86"
          width="6"
          height="5"
          transform="translate(12849 1900.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_87-17"
          data-name="Rectangle 87"
          width="6"
          height="5"
          transform="translate(12827 1900.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_88-17"
          data-name="Rectangle 88"
          width="6"
          height="5"
          transform="translate(12805 1900.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_89-17"
          data-name="Rectangle 89"
          width="6"
          height="5"
          transform="translate(12783 1900.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_90-17"
          data-name="Rectangle 90"
          width="6"
          height="5"
          transform="translate(12761 1900.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_86-18"
          data-name="Rectangle 86"
          width="6"
          height="5"
          transform="translate(12849 1990.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_87-18"
          data-name="Rectangle 87"
          width="6"
          height="5"
          transform="translate(12827 1990.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_88-18"
          data-name="Rectangle 88"
          width="6"
          height="5"
          transform="translate(12805 1990.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_89-18"
          data-name="Rectangle 89"
          width="6"
          height="5"
          transform="translate(12783 1990.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_90-18"
          data-name="Rectangle 90"
          width="6"
          height="5"
          transform="translate(12761 1990.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_86-19"
          data-name="Rectangle 86"
          width="6"
          height="6"
          transform="translate(12849 2079.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_87-19"
          data-name="Rectangle 87"
          width="6"
          height="6"
          transform="translate(12827 2079.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_88-19"
          data-name="Rectangle 88"
          width="6"
          height="6"
          transform="translate(12805 2079.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_89-19"
          data-name="Rectangle 89"
          width="6"
          height="6"
          transform="translate(12783 2079.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_90-19"
          data-name="Rectangle 90"
          width="6"
          height="6"
          transform="translate(12761 2079.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_86-20"
          data-name="Rectangle 86"
          width="6"
          height="6"
          transform="translate(12849 2168.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_87-20"
          data-name="Rectangle 87"
          width="6"
          height="6"
          transform="translate(12827 2168.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_88-20"
          data-name="Rectangle 88"
          width="6"
          height="6"
          transform="translate(12805 2168.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_89-20"
          data-name="Rectangle 89"
          width="6"
          height="6"
          transform="translate(12783 2168.188)"
          fill="#a14c9d"
        />
        <rect
          id="Rectangle_90-20"
          data-name="Rectangle 90"
          width="6"
          height="6"
          transform="translate(12761 2168.188)"
          fill="#a14c9d"
        />
      </g>
    </svg>
  );
};

export default RectangleSVG;
