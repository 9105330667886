import { Theme, createStyles } from '@material-ui/core';

const styles = ({ spacing, breakpoints }: Theme) =>
  createStyles({
    textItem: {
      marginTop: spacing(8),

      [breakpoints.up('md')]: {
        paddingLeft: spacing(5),
        marginTop: 0,
      },
    },
    text: {
      marginBottom: spacing(1),
      maxWidth: 720,
    },
    image: {
      width: '100%',
      marginTop: spacing(4),
    },
    photo: {
      marginLeft: '-1vh',

      [breakpoints.up('sm')]: {
        marginLeft: '-2vh',
      },

      [breakpoints.up('md')]: {
        marginLeft: '-1vh',
      },
    },
  });

export default styles;
