import React from 'react';

import AboutMe from 'Containers/AboutMe';
import Portfolio from 'Containers/Portfiolio';
import Contact from 'Containers/Contact';
import { Header } from 'Components/Header';
import { Footer } from 'Components/Footer';
import Skills from 'Containers/Skills';
import Home from 'Containers/Home';

const HomePage = () => {
  return (
    <>
      <Header />
      <Home />
      <AboutMe />
      <Skills />
      <Portfolio />
      <Contact />
      <Footer />
    </>
  );
};

export default HomePage;
