import React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import styles from './styles';

export interface Props extends WithStyles<typeof styles> {}

interface IProps {
  classes: Partial<Record<string, string>>;
}

const AdobeDimensionIconSVG = (props: IProps) => {
  const { classes } = props;

  return (
    <svg viewBox="0 0 240 234" className={classes.width}>
      <path
        className={classes.midGreen}
        d="M197.5,234h-155C19,234,0,215,0,191.5v-149C0,19,19,0,42.5,0h155C221,0,240,19,240,42.5v149
	C240,215,221,234,197.5,234z"
      />
      <path
        className={classes.darkGreen}
        d="M193.7,224H46.3C26.3,224,10,207.7,10,187.6V46.4C10,26.3,26.3,10,46.3,10h147.3c20.1,0,36.3,16.3,36.3,36.3
	v141.3C230,207.7,213.7,224,193.7,224z"
      />
      <g>
        <path
          className={classes.lightGreen}
          d="M42.2,54.1c0-0.8,0.2-1.2,1.2-1.3c3.8-0.2,14.8-0.5,26.1-0.5c33.7,0,50.5,21.8,50.5,53.7
		c0,44.4-29.1,57.8-51.5,57.8c-8.5,0-20.8-0.2-25.3-0.3c-0.7,0-1-0.5-1-1.3V54.1z M60.5,147.5c2.8,0.2,5.1,0.3,8.3,0.3
		c18.6,0,32.4-13.1,32.6-41.4c0.2-27.6-14.1-38.2-31.9-38.2c-3.5,0-6.1,0.2-9,0.3V147.5z"
        />
        <path
          className={classes.lightGreen}
          d="M136.2,98.3c0-1,0-4.5-0.5-10.3c0-0.7,0.2-0.8,0.8-1.2c8.5-3.2,19.9-6.6,31.4-6.6c14.1,0,29.9,5.5,29.9,29.8
		v52.2c0,1-0.3,1.3-1.2,1.3h-15.8c-1,0-1.3-0.5-1.3-1.3v-50.9c0-9.6-4-15-13.9-15c-4.3,0-8.1,0.8-11.1,1.8v64.2c0,0.7-0.3,1.2-1,1.2
		h-16.1c-0.8,0-1.2-0.3-1.2-1.2V98.3z"
        />
      </g>
    </svg>
  );
};

export default withStyles(styles)(AdobeDimensionIconSVG);
