export { default as Header } from './Header';

export const navButtonTitles = [
  'Home',
  'About me',
  'Skills',
  'Portfolio',
  // 'Blog',
  // 'Shop',
  'Contact',
];
