import React from 'react';

interface IProps {
  className?: string;
}

const ThickCircleSVG = (props: IProps) => {
  const { className } = props;

  return (
    <svg
      width="296"
      viewBox="0 0 296 296"
      className={className}
    >
      <g
        id="Ellipse_17"
        data-name="Ellipse 17"
        fill="none"
        stroke="#a14c9d"
        strokeWidth="35"
      >
        <circle cx="148" cy="148" r="148" stroke="none" />
        <circle cx="148" cy="148" r="130.5" fill="none" />
      </g>
    </svg>
  );
};

export default ThickCircleSVG;
