import { Theme, createStyles } from '@material-ui/core';

const styles = ({ breakpoints, spacing }: Theme) =>
  createStyles({
    svgViewBox: {
      overflow: 'visible',
      marginTop: spacing(2),
    },
    svgGroup: {
      transform: 'scale(10) translate(-3%, -1.5%)',

      [breakpoints.up('sm')]: {
        transform: 'scale(10) translate(-3%, -0.5%)',
      },
    },
    svg: {
      transform: `scale(0.21) translate(11%, -20%)`,

      [breakpoints.up('md')]: {
        transform: 'scale(0.21) translate(10%, -29%)',
      },
    },
    avatar: {
      transform: `scale(0.4) translate(293px, 176px)`,

      [breakpoints.up('md')]: {
        transform: 'scale(0.4) translate(281px, 57px)',
      },
    },
  });

export default styles;
