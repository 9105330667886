import React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import styles from './styles';

export interface Props extends WithStyles<typeof styles> {}

interface IProps {
  classes: Partial<Record<string, string>>;
  className?: string;
  color?: string;
}

const OutlinedTriangleSVG = (props: IProps) => {
  const { classes, className, color } = props;

  return (
    <svg
      width="147.468"
      viewBox="0 0 147.468 135.448"
      className={classNames(classes.triangle, className)}
    >
      <g
        id="Path_131"
        data-name="Path 131"
        transform="matrix(-0.966, -0.259, 0.259, -0.966, 119.775, 135.448)"
        fill="none"
        opacity="0.3"
      >
        <path d="M62,0l62,107H0Z" stroke="none" />
        <path
          d="M 62.00000381469727 5.983795166015625 L 5.20556640625 104 L 118.7944412231445 104 L 62.00000381469727 5.983795166015625 M 62.00000381469727 0 L 124 107 L 7.62939453125e-06 107 L 62.00000381469727 0 Z"
          stroke="none"
          fill={color || '#70356d' }
        />
      </g>
    </svg>
  );
};

export default withStyles(styles)(OutlinedTriangleSVG);
