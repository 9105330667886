import React from 'react';

import { SkillsSection } from 'Components/SkillsSection';

class Skills extends React.Component {
  public render() {
    return (
      <SkillsSection />
    );
  }
}

export default Skills;
