import React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { HashLink } from 'react-router-hash-link';

import styles from './styles';

export interface Props extends WithStyles<typeof styles> {}

interface IProps {
  classes: Partial<Record<string, string>>;
  title: string;
  outlined?: boolean;
  link: string;
}

const CustomButton = (props: IProps) => {
  const { classes, title, outlined, link } = props;

  return (
    <HashLink
      to={`#${link}`}
      scroll={(el: any) =>
        el.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }
    >
      <Button
        className={classNames(classes.button, {
          [`${classes.outlined}`]: outlined,
          [`${classes.contained}`]: !outlined,
        })}
        variant={outlined ? 'outlined' : 'contained'}
      >
        {title}
      </Button>
    </HashLink>
  );
};

export default withStyles(styles)(CustomButton);
