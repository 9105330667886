import { Theme, createStyles } from '@material-ui/core';

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    paper: {
      padding: spacing(2),
    },
    linearProgress: {
      marginTop: spacing(1),
      marginBottom: spacing(1),
    },
    subtitle: {
      color: palette.secondary.dark,
      textAlign: 'start',
    },
  });

export default styles;
