import { Theme, createStyles } from '@material-ui/core';

import { variables } from 'Theme/variables';

const styles = ({ spacing, breakpoints }: Theme) =>
  createStyles({
    container: {
      paddingBottom: 180,
      boxSizing: 'border-box',
      marginTop: -10 * spacing(1),
      marginBottom: 5 * spacing(1),

      [breakpoints.up('md')]: {
        marginTop: -1 * spacing(22.5),
      },

      [breakpoints.up('lg')]: {
        marginTop: -1 * spacing(37.5),
      },
    },
    item: {
      marginBottom: spacing(4),

      [breakpoints.up('md')]: {
        marginBottom: 0,
      },
    },
    textItem: {
      textAlign: 'justify',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      [breakpoints.up('md')]: {
        paddingLeft: spacing(6),
        textAlign: 'left',
        alignItems: 'unset',
        justifyContent: 'center',
      },
    },
    heading: {
      marginBottom: spacing(1),
      textAlign: 'center',

      [breakpoints.up('md')]: {
        textAlign: 'left',
      },
    },
    circle: {
      transform: 'scale(0.5) translate(-316px, 177px)',

      [breakpoints.up('sm')]: {
        transform: 'scale(0.5) translate(-316px, 220px)',
      },

      [`@media screen and (min-width: ${variables.breakpoints.desktop_768}px)`]: {
        transform: 'scale(0.5) translate(-316px, 250px)',
      },

      [breakpoints.up('md')]: {
        transform: 'scale(0.5) translate(-316px, -6px)',
      },

      [breakpoints.up('lg')]: {
        transform: 'scale(0.5) translate(-316px, -240px)',
      },

      [breakpoints.up('xl')]: {
        transform: 'scale(0.5) translate(-316px, -180px)',
      },
    },
    triangle: {
      transform: 'scale(0.5) translate(35vw, 117vh)',
      opacity: 0.5,

      [breakpoints.up('sm')]: {
        transform: 'translate(25vw, 37vh)',
      },
    },
    text: {
      maxWidth: 720,
    },
  });

export default styles;
