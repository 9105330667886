import React from 'react';

const MenuIcon = () => {
  return (
    <svg width="30.5" height="19" viewBox="0 0 30.5 19">
      <g id="Group_294" data-name="Group 294" transform="translate(-293.5 -29)">
        <line
          id="Line_34"
          data-name="Line 34"
          x2="27.5"
          transform="translate(295 30.5)"
          fill="none"
          stroke="#151d41"
          strokeLinecap="round"
          strokeWidth="3"
        />
        <line
          id="Line_35"
          data-name="Line 35"
          x2="20.5"
          transform="translate(295 38.5)"
          fill="none"
          stroke="#151d41"
          strokeLinecap="round"
          strokeWidth="3"
        />
        <line
          id="Line_36"
          data-name="Line 36"
          x2="13.5"
          transform="translate(295 46.5)"
          fill="none"
          stroke="#151d41"
          strokeLinecap="round"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
};

export default MenuIcon;
