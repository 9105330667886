import { Theme, createStyles } from '@material-ui/core';
import { variables } from 'Theme/variables';

const styles = ({ spacing, breakpoints }: Theme) =>
  createStyles({
    section: {
      display: 'flex',
      textAlign: 'justify',
      alignItems: 'center',
      flexDirection: 'column',
      paddingBottom: spacing(8),
    },
    heading: {
      marginBottom: spacing(2),
    },
    svgItem: {
      display: 'none',
      marginTop: spacing(2),

      [breakpoints.up('md')]: {
        display: 'block',
        marginLeft: -1 * spacing(5.4),
      },

      [breakpoints.up('lg')]: {
        marginLeft: -1 * spacing(2.6),
        marginTop: 0,
      },

      [breakpoints.up('xl')]: {
        marginLeft: spacing(4.3),
      },
    },

    item: {
      [`@media screen and (min-width: ${variables.breakpoints.desktop_1780}px)`]: {
        marginLeft: spacing(8.1),
      },
    },
    triangle: {
      transform: 'translate(-40vw, -23vh)',

      [breakpoints.up('xl')]: {
        transform: 'translate(-40vw, -10vh)',
      },
    },
  });

export default styles;
