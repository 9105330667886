export { default as HomeSection } from './HomeSection';

export const buttons = [
  {
    title: 'Say Hello',
    variant: 'contained',
    link: 'Contact',
  },
  {
    title: 'Explore',
    variant: 'outlined',
    link:  'About me',
  },
];
