import { Theme, createStyles } from '@material-ui/core';

const styles = ({ spacing }: Theme) =>
  createStyles({
    section: {
      display: 'flex',
      textAlign: 'justify',
      alignItems: 'center',
      flexDirection: 'column',
      paddingBottom: spacing(8),
      paddingTop: spacing(5.6),
    },
    heading: {
      marginBottom: spacing(2),
      textAlign: 'center',
    },
    button: {
      marginTop: spacing(4),
      padding: spacing(1),
    },
    field: {
      marginBottom: spacing(1),
    },
  });

export default styles;
