import React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import classNames from 'classnames';

import styles from './styles';

export interface Props extends WithStyles<typeof styles> {}

interface IProps {
  classes: Partial<Record<string, string>>;
  icon: React.ReactNode;
  label: string;
  link: string;
  className?: string;
}

const SocialMediaIcon = (props: IProps) => {
  const { classes, icon, label, link, className} = props;

  return (
    <Link href={link} target="_blank">
      <IconButton
        disableRipple
        aria-label={label}
        className={classNames(classes.socialmediaListItem, className)}
        component="li"
      >
        {icon}
      </IconButton>
    </Link>
  );
};

export default withStyles(styles)(SocialMediaIcon);
