import React, { useContext } from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import styles from './styles';
import { Section } from 'Components/Section';
import { Title } from 'Components/Title';
import { UserContext } from 'Context/UserContext';
import { IUser } from 'Components/types';
import { heading } from './index';
import { FilledTriangleSVG } from 'Components/FilledTriangleSVG';
import { ThickCircleSVG } from 'Components/ThickCircleSVG';
import { DottedRectanglePhotoSVG } from 'Components/DottedRectanglePhotoSVG';

export interface Props extends WithStyles<typeof styles> {}

interface IProps {
  classes: Partial<Record<string, string>>;
}

const AboutMeSection = (props: IProps) => {
  const { classes } = props;
  const userState = useContext(UserContext);
  const user: IUser = userState[0] && userState[0].user;
  const profile = user && user.profile;

  return (
    <Section id={'About me'}>
      <ThickCircleSVG className={classes.circle} />
      <Grid container className={classes.container}>
        {profile && (
          <>
            <Grid item xs={12} md={4} container className={classes.item}>
              <DottedRectanglePhotoSVG source={profile.secondPhotoUrl} />
            </Grid>

            <Grid item xs={12} md={8} className={classes.textItem}>
              <Title title={`About Me`} />
                <Typography variant={'h4'} className={classes.heading}>
                  {heading}
                </Typography>
                <Typography className={classes.text} variant={'body1'}>{profile.aboutMe}</Typography>
                <FilledTriangleSVG className={classes.triangle} />
            </Grid>
          </>
        )}
      </Grid>
    </Section>
  );
};

export default withStyles(styles)(AboutMeSection);
