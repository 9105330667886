import { Theme, createStyles } from '@material-ui/core';

const styles = ({ spacing, breakpoints, palette }: Theme) =>
  createStyles({
    logo: {
      width: '40%',
      maxWidth: 60,
      marginTop: spacing(2),
      marginBottom: spacing(2),
      marginRight: spacing(2),
      marginLeft: 0,
    },
    logoLink: {
      display: 'contents',
    },
  });

export default styles;
