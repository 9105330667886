import { Theme, createStyles } from '@material-ui/core';

const styles = ({ spacing, breakpoints }: Theme) =>
  createStyles({
    section: {
      height: '100%',
      minHeight: 400,
      padding: spacing(2),

      [breakpoints.up('sm')]: {
        padding: spacing(3),
      },
    },
  });

export default styles;
