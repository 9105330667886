export { default as PortfolioSection } from './PortfolioSection';

export const cardButton = ['view project'];
export const heading = 'My Work Examples';

export const chips = [
  { title: 'All' },
  { title: 'Branding' },
  { title: 'UX/UI' },
  { title: 'Web Development' },
];
