import React, { useContext } from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Link } from '@material-ui/core';

import styles from './styles';
import { Header } from 'Components/Header';
import { Footer } from 'Components/Footer';
import { Section } from 'Components/Section';
import { Title } from 'Components/Title';
import { DottedRectanglePhotoSVG } from 'Components/DottedRectanglePhotoSVG';
import { UserContext } from 'Context/UserContext';
import { IProject, IUser } from 'Components/types';

export interface Props extends WithStyles<typeof styles> {}

interface IProps {
  classes: Partial<Record<string, string>>;
  match: { params: { id: string } };
}

const Project = (props: IProps) => {
  const { classes } = props;
  const { id } = props.match.params;
  const userState = useContext(UserContext);
  const user: IUser = userState[0] && userState[0].user;
  const projects = user && user.projects;
  const project: IProject[] =
    projects && projects.filter((project) => project.id === +id); // The + sign convert id(string) into a number. It's the same as using parseInt.
  const thumbnailUrl = project && project[0].thumbnailUrl;
  const caseStudy = project && project[0].caseStudy;
  const images = project && project[0].images;

  return (
    <div id={'Project'}>
      <Header redirect />
      <Section className={classes.section}>
        <Grid container>
          {thumbnailUrl && (
            <Grid item xs={12} md={4} container>
              <DottedRectanglePhotoSVG
                source={thumbnailUrl}
                className={classes.photo}
              />
            </Grid>
          )}
          {caseStudy && (
            <Grid item xs={12} md={8} className={classes.textItem}>
              <Title title={`Case study`} />
              <Typography className={classes.text} variant={'body1'}>
                {caseStudy.client && (
                  <div>
                    <strong>Client: </strong>
                    {`${caseStudy.client}`}
                  </div>
                )}
              </Typography>
              <Typography className={classes.text} variant={'body1'}>
                {caseStudy.category && (
                  <>
                    <strong>Category: </strong>
                    {`${caseStudy.category}`}
                  </>
                )}
              </Typography>
              <Typography className={classes.text} variant={'body1'}>
                {caseStudy.scope && (
                  <>
                    <strong>Scope: </strong>
                    {`${caseStudy.scope}`}
                  </>
                )}
              </Typography>
              <Typography className={classes.text} variant={'body1'}>
                {caseStudy.projectObjectives && (
                  <>
                    <strong>Project Objectives: </strong>
                    {`${caseStudy.projectObjectives}`}
                  </>
                )}
              </Typography>
              <Typography>
                {caseStudy.website && (
                  <>
                    <strong>Website: </strong>
                    <Link
                      href={caseStudy.website}
                      target="_blank"
                      color="primary"
                    >
                      {caseStudy.website}
                    </Link>
                  </>
                )}
              </Typography>
            </Grid>
          )}
          {images &&
            images.map((image: any) => (
              <Grid item xs={12} md={6} key={image.id}>
                <img
                  src={image.url}
                  alt={image.alternativeText}
                  className={classes.image}
                />
              </Grid>
            ))}
        </Grid>
      </Section>
      <Footer anchor={'Project'} redirect/>
    </div>
  );
};

export default withStyles(styles)(Project);
