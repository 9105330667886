import { Theme, createStyles } from '@material-ui/core';

const styles = ({ palette, breakpoints, spacing }: Theme) =>
  createStyles({
    button: {
      padding: `${spacing(1)}px ${spacing(2)}px ${spacing(1)}px ${spacing(2)}px`,
      display: 'none',
      float: 'right',

      [breakpoints.up('md')]: {
        display: 'inline-block',
      },
    },
    outlined: {
      color: palette.primary.main,
      borderColor: palette.primary.main,

      '&:hover': {
        background: palette.primary.dark,
        color: palette.common.white,
        transition: 'all 0.3s ease-in-out',
      },
    },
    contained: {
      backgroundColor: palette.common.white,
      color: palette.primary.main,

      '&:hover': {
        background: palette.primary.main,
        color: palette.common.white,
        transition: 'all 0.3s ease-in-out',
      },
    },
  });

export default styles;
