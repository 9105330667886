import React, { useContext } from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';

import styles from './styles';
import { Section } from 'Components/Section';
import { Title } from 'Components/Title';
import { heading } from './index';
import { RectangleSVG } from 'Components/RectangleSVG';
import { SkillCard } from 'Components/Skillcard';
import { OutlinedTriangleSVG } from 'Components/OutlinedTriangleSVG';
import { IUser } from 'Components/types';
import { UserContext } from 'Context/UserContext';

export interface Props extends WithStyles<typeof styles> {}

interface IProps {
  classes: Partial<Record<string, string>>;
}

const SkillsSection = (props: IProps) => {
  const { classes } = props;
  const userState = useContext(UserContext);
  const user: IUser = userState[0] && userState[0].user;
  const skills = user && user.skills;

  return (
    <Section id={'Skills'} className={classes.section}>
      <OutlinedTriangleSVG className={classes.triangle} />
      <Title title={`Skills`} />
      <Typography variant={'h4'} className={classes.heading}>
        {heading}
      </Typography>
      <Grid container spacing={4}>
        <Grid
          item
          className={classes.svgItem}
        >
          <RectangleSVG />
        </Grid>
        <Grid
          item
          className={classes.item}
          xs={12}
          md={10}
        >
          <Grid container spacing={4}>
            {skills && skills.map((skill) => (
              <Grid item xs={12} md={4} key={skill.id}>
                <SkillCard skill={skill} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Section>
  );
};

export default withStyles(styles)(SkillsSection);
