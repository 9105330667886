import { Theme, createStyles } from '@material-ui/core';

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    title: {
      backgroundColor: palette.secondary.light,
      color: palette.primary.dark,
      padding: spacing(1),
      width: '100%',
      maxWidth: 120,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      borderRadius: 5,
      marginBottom: spacing(2),
    },
  });

export default styles;
