import React, { useContext } from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Fab } from '@material-ui/core';
import { HashLink } from 'react-router-hash-link';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import classNames from 'classnames';

import styles from './styles';
import { Section } from 'Components/Section';
import { getYear } from 'utils';
import { IUser } from 'Components/types';
import { SocialMediaIcons } from 'Components/SocialMediaIcons';
import { Logo } from 'Components/Logo';
import { UserContext } from 'Context/UserContext';
import {
  shop,
  templates,
  sentEmail,
  getIntouched,
  gumroadLink,
  backupEmail,
} from './index';

export interface Props extends WithStyles<typeof styles> {}

interface IProps {
  classes: Partial<Record<string, string>>;
  anchor?: string;
  redirect?: boolean;
}

const Footer = (props: IProps) => {
  const { classes, anchor } = props;
  const userState = useContext(UserContext);
  const user: IUser = userState[0] && userState[0].user;
  const brand = user && user.brand;
  const socialmedias = user && user.socialmedias;
  const email = user && user.profile.email;
  const firstname = user && user.profile.firstname;
  const lastname = user && user.profile.lastname;

  return (
    <Section id={'Footer'} className={classes.section}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container className={classes.container}>
            <Grid item xs={12} md={4} className={classes.grid}>
              {brand && (
                <Grid item xs={12}>
                  <div className={classes.logoContainer}>
                    <Logo redirect />
                  </div>

                  <Typography className={classes.text} variant="body1">
                    {brand.description}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              className={classNames(classes.grid, classes.rightGrid)}
            >
              <Grid item>
                <Typography variant="h6" className={classes.text}>
                  {brand && brand.slogan}
                </Typography>
              </Grid>
              <Grid item className={classes.items}>
                <Grid>
                  <ul className={classes.list}>
                    <li>
                      <Typography variant="body1" className={classes.title}>
                        {shop}
                      </Typography>
                    </li>
                    <li className={classes.listItem}>
                      <Typography variant="body1">
                        <a
                          target="_blank"
                          href={`${gumroadLink}`}
                          className={classes.link}
                          rel="noopener noreferrer"
                        >
                          {templates}
                        </a>
                      </Typography>
                    </li>
                  </ul>
                </Grid>
                <Grid>
                  <ul className={classes.list}>
                    <Typography variant="body1" className={classes.title}>
                      {sentEmail}
                    </Typography>
                    <li className={classes.listItem}>
                      <a
                        href={`mailto:${backupEmail}?cc=${email}`}
                        className={classes.link}
                      >
                        {backupEmail}
                      </a>
                    </li>
                  </ul>
                </Grid>
                <Grid>
                  <div className={classes.list}>
                    <Typography variant="body1" className={classNames(classes.title, classes.socialmediaIconsTitle)}>
                      {getIntouched}
                    </Typography>
                    <SocialMediaIcons
                      inline
                      socialmedias={socialmedias}
                      className={classes.socialmediaIcons}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <hr />
          <Grid container>
            <Grid item xs={9} className={classes.copyright}>
              <Typography variant="h6">
                {`Copyright © ${getYear()} ${firstname} ${lastname}`}
              </Typography>
            </Grid>
            <Grid item xs={3} className={classes.fabGridItem}>
              <HashLink
                to={anchor ? `#${anchor}` : '#Home'}
                scroll={(el: any) =>
                  el.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }
                className={classes.link}
              >
                <Fab
                  variant="extended"
                  aria-label="navigation"
                  className={classes.fab}
                >
                  <ArrowUpwardIcon className={classes.extendedIcon} />
                </Fab>
              </HashLink>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Section>
  );
};

export default withStyles(styles)(Footer);
