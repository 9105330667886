import { Theme, createStyles } from '@material-ui/core';

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    socialmediaListItem: {
      paddingBottom: spacing(1),
      display: 'block',
      fontSize: 25,
      marginLeft: -1 * spacing(2),
      borderRadius: '0px 60px 60px 0px',
      paddingLeft: spacing(2),
      paddingTop: spacing(1),
      width: 50,

      '&:hover': {
        backgroundColor: palette.primary.main,
        color: palette.common.white,
        transition: 'all 0.3s ease-in-out',
      },
    },
  });

export default styles;
