export const mergeArrays = (array: any[]) =>  [].concat(...array);
export const minText = (min: number) => `Must be at least ${min} characters.`;
export const maxText = (max: number) => `Must be ${max} characters or less.`;
export const invalidText = (name: string) => `Invalid ${name} address.`;

export const getYear = () => {
  let date = new Date();
  let year = date.getFullYear();

  return year;
};
