import React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import styles from './styles';

export interface Props extends WithStyles<typeof styles> {}

interface IProps {
  classes: Partial<Record<string, string>>;
  title: string;
}

const Title = (props: IProps) => {
  const { classes, title } = props;

  return (
    <div className={classes.title}>
      <Typography variant="body1">{title}</Typography>
    </div>
  );
};

export default withStyles(styles)(Title);
