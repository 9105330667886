import { Theme, createStyles } from '@material-ui/core';

import { variables } from 'Theme/variables';

const styles = ({ palette, breakpoints, spacing }: Theme) =>
  createStyles({
    socialmediaList: {
      listStyle: 'none',
      paddingLeft: 0,
      color: palette.secondary.dark,
      display: 'block',
      position: 'relative',
      maxWidth: 60,

      [breakpoints.up('sm')]: {
        top: '8vh',
        marginLeft: -1 * spacing(1),
      },

      [breakpoints.up('md')]: {
        top: '2vh',
      },

      [breakpoints.up('lg')]: {
        top: '-2vh',
      },

      [breakpoints.up('xl')]: {
        top: '-3vh',
      },

      [`@media screen and (min-width: ${variables.breakpoints.desktop_2048}px) and (min-height:${variables.breakpoints.desktop_700}px)`]: {
        top: '0vh',
      },

      [`@media screen and (min-width: ${variables.breakpoints.desktop_2560}px)`]: {
        top: '3vh',
      },
    },
    inline: {
      display: 'inline-flex',
      marginTop: 0,
      top: 0,
      marginLeft: 0,
      maxWidth: 'unset',

      [breakpoints.up('md')]: {
        marginLeft: -12,
      },

      [breakpoints.up('xl')]: {
        marginLeft: 0,
      },
    },
  });

export default styles;
