import React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardMedia,
  CardActions,
  Button,
  CardContent,
  Typography,
  Collapse,
  IconButton,
} from '@material-ui/core';
import {
  ReactJs,
  Angularjs,
  MaterialUi,
  Typescript,
  Adobexd,
  Figma,
  Adobeillustrator,
  Git,
} from '@icons-pack/react-simple-icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';

import styles from './styles';
import { IProject } from 'Components/types';
import { Link } from 'react-router-dom';
import { Technologies, Tools } from './index';
import { AdobeDimensionIconSVG } from 'Components/AdobeDimensionIconSVG';

export interface Props extends WithStyles<typeof styles> {}

interface IProps {
  classes: Partial<Record<string, string>>;
  project: IProject;
  buttons: string[];
}

const CustomCard = (props: IProps) => {
  const { classes, project, buttons } = props;
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => setExpanded(!expanded);

  return (
    <Card className={classes.card}>
      <CardMedia
        component="img"
        alt={project.title}
        height="300"
        image={project.thumbnailUrl}
        title={project.title}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {project.title}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {project.description}
        </Typography>
      </CardContent>
      <CardActions>
        {buttons.map((button) => (
          <Link to={`${project.id}`} key={project.id} className={classes.link}>
            <Button size="small" color="primary">
              {button}
            </Button>
          </Link>
        ))}
        <IconButton
          color="primary"
          className={classNames(classes.expand, {
            [`${classes.expandOpen}`]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <div className={classes.tools}>
            {project.tools && (
              <Typography
                variant="subtitle2"
                color="textSecondary"
                component="p"
              >
                {Tools}
              </Typography>
            )}

            {project.tools &&
              project.tools.adobeXd &&
              project.tools.adobeXd !== null && (
                <Adobexd className={classes.icons} color="#FF26BE" size={24} />
              )}

            {project.tools &&
              project.tools.figma &&
              project.tools.figma !== null && (
                <Figma className={classes.icons} color="#F24E1E" size={24} />
              )}

            {project.tools &&
              project.tools.adobeIllustrator &&
              project.tools.adobeIllustrator !== null && (
                <Adobeillustrator
                  className={classes.icons}
                  color="#FF9A00"
                  size={24}
                />
              )}

            {project.tools &&
              project.tools.adobeDimension &&
              project.tools.adobeDimension !== null && (
                <AdobeDimensionIconSVG />
              )}
          </div>

          <>
            {project.technologies && (
              <Typography
                variant="subtitle2"
                color="textSecondary"
                component="p"
              >
                {Technologies}
              </Typography>
            )}

            {project.technologies &&
              project.technologies.reactJS &&
              project.technologies.reactJS !== null && (
                <ReactJs className={classes.icons} color="#61DAFB" size={24} />
              )}

            {project.technologies &&
              project.technologies.angularJS &&
              project.technologies.angularJS !== null && (
                <Angularjs
                  className={classes.icons}
                  color="#DD0031"
                  size={24}
                />
              )}

            {project.technologies &&
              project.technologies.typescript &&
              project.technologies.typescript !== null && (
                <Typescript
                  className={classes.icons}
                  color="#007ACC"
                  size={24}
                />
              )}

            {project.technologies &&
              project.technologies.materialUi &&
              project.technologies.materialUi !== null && (
                <MaterialUi
                  className={classes.icons}
                  color="#0081CB"
                  size={24}
                />
              )}

            {project.technologies &&
              project.technologies.git &&
              project.technologies.git !== null && (
                <Git className={classes.icons} color="#F05032" size={24} />
              )}
          </>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default withStyles(styles)(CustomCard);
