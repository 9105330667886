import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    lightGreen: {
      fill: '#D4FCEF',
    },
    midGreen: {
      fill: '#3CF2A6',
    },
    darkGreen: {
      fill: '#00261A',
    },
    width: {
      width: 24,
    }
  });

export default styles;
