import React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';

import styles from './styles';
import { ISkill } from 'Components/types';

export interface Props extends WithStyles<typeof styles> {}

interface IProps {
  classes: Partial<Record<string, string>>;
  skill: ISkill;
}

const SkillCard = (props: IProps) => {
  const { classes, skill } = props;
  const { percentage, subtitle, title } = skill;

  return (
    <Paper elevation={0} className={classes.paper}>
      <Typography variant={'h5'}>{`${percentage}%`}</Typography>
      <LinearProgress
        variant="determinate"
        value={percentage}
        className={classes.linearProgress}
      />
      <Typography variant={'h6'}>{title}</Typography>
      <Typography variant={'subtitle1'} className={classes.subtitle}>
        {subtitle}
      </Typography>
    </Paper>
  );
};

export default withStyles(styles)(SkillCard);
