import React, { createContext, useEffect, useState, ReactNode } from 'react';
import axios from 'axios';

import { baseUrl, identifier, password } from './utils';

interface IProps {
  children: ReactNode;
}

interface IAuthAPI {
  jwt: string;
}

const defaultValue = [] as any[];

export const AuthContext = createContext(defaultValue);

const AuthProvider = (props: IProps) => {
  const [authState, setStateAuth] = useState(defaultValue);

  useEffect(() => {
    axios
      .post(`${baseUrl}/auth/local`, {
        identifier,
        password,
      })
      .then(({ data }) => setStateAuth(data))
      .catch((err) => console.error(err));
  }, []);

  return (
    <AuthContext.Provider value={[authState as IAuthAPI[]]}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
