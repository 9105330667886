import React from 'react';
import Loader from 'react-loader-spinner';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import styles from './styles';

export interface Props extends WithStyles<typeof styles> {}

interface IProps {
  classes: Partial<Record<string, string>>;
}

const Preloader = (props: IProps) => {
  const { classes } = props;

  return (
    <div className={classes.container}>
      <div className={classes.loader}>
        <Loader type="ThreeDots" color="#A14C9D" height={80} width={80} />
      </div>
    </div>
  );
};

export default withStyles(styles)(Preloader);
