import React, { ReactNode } from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import styles from './styles';

export interface Props extends WithStyles<typeof styles> {}

interface IProps {
  classes: Partial<Record<string, string>>;
  children: ReactNode;
  id?: string;
  className?: string;
}

const Section = (props: IProps) => {
  const { classes, children, id, className } = props;

  return (
    <section className={classNames(classes.section, className)} id={id}>
      {children}
    </section>
  );
};

export default withStyles(styles)(Section);
