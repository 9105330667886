import React from 'react';

interface IProps {
  source: string;
  className?: string;
}

const DottedRectanglePhotoSVG = (props: IProps) => {
  const { source, className } = props;

  return (
    <svg width="100%" viewBox="0 0 375.165 405.948" className={className}>
      <g
        id="Group_324"
        data-name="Group 324"
        transform="translate(0.165 -1138.052)"
      >
        <g id="Group_323" data-name="Group 323">
          <g
            id="Group_302"
            data-name="Group 302"
            transform="translate(77.272 1377.581)"
            opacity="0.5"
          >
            <g id="Group_97" data-name="Group 97" transform="translate(0 0)">
              <rect
                id="Rectangle_86"
                data-name="Rectangle 86"
                width="2.886"
                height="2.886"
                transform="translate(137.56)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87"
                data-name="Rectangle 87"
                width="2.886"
                height="2.886"
                transform="translate(126.979)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88"
                data-name="Rectangle 88"
                width="2.886"
                height="2.886"
                transform="translate(116.397)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89"
                data-name="Rectangle 89"
                width="2.886"
                height="2.886"
                transform="translate(105.816)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90"
                data-name="Rectangle 90"
                width="2.886"
                height="2.886"
                transform="translate(95.234)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91"
                data-name="Rectangle 91"
                width="2.886"
                height="2.886"
                transform="translate(84.653)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92"
                data-name="Rectangle 92"
                width="2.886"
                height="2.886"
                transform="translate(74.071)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93"
                data-name="Rectangle 93"
                width="2.886"
                height="2.886"
                transform="translate(63.489)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94"
                data-name="Rectangle 94"
                width="2.886"
                height="2.886"
                transform="translate(53.389)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95"
                data-name="Rectangle 95"
                width="2.886"
                height="2.886"
                transform="translate(42.326)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96"
                data-name="Rectangle 96"
                width="2.886"
                height="2.886"
                transform="translate(31.745)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97"
                data-name="Rectangle 97"
                width="2.886"
                height="2.886"
                transform="translate(21.163)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98"
                data-name="Rectangle 98"
                width="2.886"
                height="2.886"
                transform="translate(10.582)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99"
                data-name="Rectangle 99"
                width="2.886"
                height="2.886"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_106"
              data-name="Group 106"
              transform="translate(0 42.807)"
            >
              <rect
                id="Rectangle_86-2"
                data-name="Rectangle 86"
                width="2.886"
                height="2.886"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-2"
                data-name="Rectangle 87"
                width="2.886"
                height="2.886"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-2"
                data-name="Rectangle 88"
                width="2.886"
                height="2.886"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-2"
                data-name="Rectangle 89"
                width="2.886"
                height="2.886"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-2"
                data-name="Rectangle 90"
                width="2.886"
                height="2.886"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-2"
                data-name="Rectangle 91"
                width="2.886"
                height="2.886"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-2"
                data-name="Rectangle 92"
                width="2.886"
                height="2.886"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-2"
                data-name="Rectangle 93"
                width="2.886"
                height="2.886"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-2"
                data-name="Rectangle 94"
                width="2.886"
                height="2.886"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-2"
                data-name="Rectangle 95"
                width="2.886"
                height="2.886"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-2"
                data-name="Rectangle 96"
                width="2.886"
                height="2.886"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-2"
                data-name="Rectangle 97"
                width="2.886"
                height="2.886"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-2"
                data-name="Rectangle 98"
                width="2.886"
                height="2.886"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-2"
                data-name="Rectangle 99"
                width="2.886"
                height="2.886"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_111"
              data-name="Group 111"
              transform="translate(0 86.095)"
            >
              <rect
                id="Rectangle_86-3"
                data-name="Rectangle 86"
                width="2.886"
                height="2.405"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-3"
                data-name="Rectangle 87"
                width="2.886"
                height="2.405"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-3"
                data-name="Rectangle 88"
                width="2.886"
                height="2.405"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-3"
                data-name="Rectangle 89"
                width="2.886"
                height="2.405"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-3"
                data-name="Rectangle 90"
                width="2.886"
                height="2.405"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-3"
                data-name="Rectangle 91"
                width="2.886"
                height="2.405"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-3"
                data-name="Rectangle 92"
                width="2.886"
                height="2.405"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-3"
                data-name="Rectangle 93"
                width="2.886"
                height="2.405"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-3"
                data-name="Rectangle 94"
                width="2.886"
                height="2.405"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-3"
                data-name="Rectangle 95"
                width="2.886"
                height="2.405"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-3"
                data-name="Rectangle 96"
                width="2.886"
                height="2.405"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-3"
                data-name="Rectangle 97"
                width="2.886"
                height="2.405"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-3"
                data-name="Rectangle 98"
                width="2.886"
                height="2.405"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-3"
                data-name="Rectangle 99"
                width="2.886"
                height="2.405"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_116"
              data-name="Group 116"
              transform="translate(0 129.384)"
            >
              <rect
                id="Rectangle_86-4"
                data-name="Rectangle 86"
                width="2.886"
                height="2.405"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-4"
                data-name="Rectangle 87"
                width="2.886"
                height="2.405"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-4"
                data-name="Rectangle 88"
                width="2.886"
                height="2.405"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-4"
                data-name="Rectangle 89"
                width="2.886"
                height="2.405"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-4"
                data-name="Rectangle 90"
                width="2.886"
                height="2.405"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-4"
                data-name="Rectangle 91"
                width="2.886"
                height="2.405"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-4"
                data-name="Rectangle 92"
                width="2.886"
                height="2.405"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-4"
                data-name="Rectangle 93"
                width="2.886"
                height="2.405"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-4"
                data-name="Rectangle 94"
                width="2.886"
                height="2.405"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-4"
                data-name="Rectangle 95"
                width="2.886"
                height="2.405"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-4"
                data-name="Rectangle 96"
                width="2.886"
                height="2.405"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-4"
                data-name="Rectangle 97"
                width="2.886"
                height="2.405"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-4"
                data-name="Rectangle 98"
                width="2.886"
                height="2.405"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-4"
                data-name="Rectangle 99"
                width="2.886"
                height="2.405"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_98"
              data-name="Group 98"
              transform="translate(0 8.658)"
            >
              <rect
                id="Rectangle_86-5"
                data-name="Rectangle 86"
                width="2.886"
                height="2.405"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-5"
                data-name="Rectangle 87"
                width="2.886"
                height="2.405"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-5"
                data-name="Rectangle 88"
                width="2.886"
                height="2.405"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-5"
                data-name="Rectangle 89"
                width="2.886"
                height="2.405"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-5"
                data-name="Rectangle 90"
                width="2.886"
                height="2.405"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-5"
                data-name="Rectangle 91"
                width="2.886"
                height="2.405"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-5"
                data-name="Rectangle 92"
                width="2.886"
                height="2.405"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-5"
                data-name="Rectangle 93"
                width="2.886"
                height="2.405"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-5"
                data-name="Rectangle 94"
                width="2.886"
                height="2.405"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-5"
                data-name="Rectangle 95"
                width="2.886"
                height="2.405"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-5"
                data-name="Rectangle 96"
                width="2.886"
                height="2.405"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-5"
                data-name="Rectangle 97"
                width="2.886"
                height="2.405"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-5"
                data-name="Rectangle 98"
                width="2.886"
                height="2.405"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-5"
                data-name="Rectangle 99"
                width="2.886"
                height="2.405"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_105"
              data-name="Group 105"
              transform="translate(0 51.946)"
            >
              <rect
                id="Rectangle_86-6"
                data-name="Rectangle 86"
                width="2.886"
                height="2.405"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-6"
                data-name="Rectangle 87"
                width="2.886"
                height="2.405"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-6"
                data-name="Rectangle 88"
                width="2.886"
                height="2.405"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-6"
                data-name="Rectangle 89"
                width="2.886"
                height="2.405"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-6"
                data-name="Rectangle 90"
                width="2.886"
                height="2.405"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-6"
                data-name="Rectangle 91"
                width="2.886"
                height="2.405"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-6"
                data-name="Rectangle 92"
                width="2.886"
                height="2.405"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-6"
                data-name="Rectangle 93"
                width="2.886"
                height="2.405"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-6"
                data-name="Rectangle 94"
                width="2.886"
                height="2.405"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-6"
                data-name="Rectangle 95"
                width="2.886"
                height="2.405"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-6"
                data-name="Rectangle 96"
                width="2.886"
                height="2.405"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-6"
                data-name="Rectangle 97"
                width="2.886"
                height="2.405"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-6"
                data-name="Rectangle 98"
                width="2.886"
                height="2.405"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-6"
                data-name="Rectangle 99"
                width="2.886"
                height="2.405"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_110"
              data-name="Group 110"
              transform="translate(0 94.753)"
            >
              <rect
                id="Rectangle_86-7"
                data-name="Rectangle 86"
                width="2.886"
                height="2.886"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-7"
                data-name="Rectangle 87"
                width="2.886"
                height="2.886"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-7"
                data-name="Rectangle 88"
                width="2.886"
                height="2.886"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-7"
                data-name="Rectangle 89"
                width="2.886"
                height="2.886"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-7"
                data-name="Rectangle 90"
                width="2.886"
                height="2.886"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-7"
                data-name="Rectangle 91"
                width="2.886"
                height="2.886"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-7"
                data-name="Rectangle 92"
                width="2.886"
                height="2.886"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-7"
                data-name="Rectangle 93"
                width="2.886"
                height="2.886"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-7"
                data-name="Rectangle 94"
                width="2.886"
                height="2.886"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-7"
                data-name="Rectangle 95"
                width="2.886"
                height="2.886"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-7"
                data-name="Rectangle 96"
                width="2.886"
                height="2.886"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-7"
                data-name="Rectangle 97"
                width="2.886"
                height="2.886"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-7"
                data-name="Rectangle 98"
                width="2.886"
                height="2.886"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-7"
                data-name="Rectangle 99"
                width="2.886"
                height="2.886"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_115"
              data-name="Group 115"
              transform="translate(0 137.56)"
            >
              <rect
                id="Rectangle_86-8"
                data-name="Rectangle 86"
                width="2.886"
                height="2.886"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-8"
                data-name="Rectangle 87"
                width="2.886"
                height="2.886"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-8"
                data-name="Rectangle 88"
                width="2.886"
                height="2.886"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-8"
                data-name="Rectangle 89"
                width="2.886"
                height="2.886"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-8"
                data-name="Rectangle 90"
                width="2.886"
                height="2.886"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-8"
                data-name="Rectangle 91"
                width="2.886"
                height="2.886"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-8"
                data-name="Rectangle 92"
                width="2.886"
                height="2.886"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-8"
                data-name="Rectangle 93"
                width="2.886"
                height="2.886"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-8"
                data-name="Rectangle 94"
                width="2.886"
                height="2.886"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-8"
                data-name="Rectangle 95"
                width="2.886"
                height="2.886"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-8"
                data-name="Rectangle 96"
                width="2.886"
                height="2.886"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-8"
                data-name="Rectangle 97"
                width="2.886"
                height="2.886"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-8"
                data-name="Rectangle 98"
                width="2.886"
                height="2.886"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-8"
                data-name="Rectangle 99"
                width="2.886"
                height="2.886"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_99"
              data-name="Group 99"
              transform="translate(0 17.315)"
            >
              <rect
                id="Rectangle_86-9"
                data-name="Rectangle 86"
                width="2.886"
                height="2.886"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-9"
                data-name="Rectangle 87"
                width="2.886"
                height="2.886"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-9"
                data-name="Rectangle 88"
                width="2.886"
                height="2.886"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-9"
                data-name="Rectangle 89"
                width="2.886"
                height="2.886"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-9"
                data-name="Rectangle 90"
                width="2.886"
                height="2.886"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-9"
                data-name="Rectangle 91"
                width="2.886"
                height="2.886"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-9"
                data-name="Rectangle 92"
                width="2.886"
                height="2.886"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-9"
                data-name="Rectangle 93"
                width="2.886"
                height="2.886"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-9"
                data-name="Rectangle 94"
                width="2.886"
                height="2.886"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-9"
                data-name="Rectangle 95"
                width="2.886"
                height="2.886"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-9"
                data-name="Rectangle 96"
                width="2.886"
                height="2.886"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-9"
                data-name="Rectangle 97"
                width="2.886"
                height="2.886"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-9"
                data-name="Rectangle 98"
                width="2.886"
                height="2.886"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-9"
                data-name="Rectangle 99"
                width="2.886"
                height="2.886"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_104"
              data-name="Group 104"
              transform="translate(0 60.604)"
            >
              <rect
                id="Rectangle_86-10"
                data-name="Rectangle 86"
                width="2.886"
                height="2.405"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-10"
                data-name="Rectangle 87"
                width="2.886"
                height="2.405"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-10"
                data-name="Rectangle 88"
                width="2.886"
                height="2.405"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-10"
                data-name="Rectangle 89"
                width="2.886"
                height="2.405"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-10"
                data-name="Rectangle 90"
                width="2.886"
                height="2.405"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-10"
                data-name="Rectangle 91"
                width="2.886"
                height="2.405"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-10"
                data-name="Rectangle 92"
                width="2.886"
                height="2.405"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-10"
                data-name="Rectangle 93"
                width="2.886"
                height="2.405"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-10"
                data-name="Rectangle 94"
                width="2.886"
                height="2.405"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-10"
                data-name="Rectangle 95"
                width="2.886"
                height="2.405"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-10"
                data-name="Rectangle 96"
                width="2.886"
                height="2.405"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-10"
                data-name="Rectangle 97"
                width="2.886"
                height="2.405"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-10"
                data-name="Rectangle 98"
                width="2.886"
                height="2.405"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-10"
                data-name="Rectangle 99"
                width="2.886"
                height="2.405"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_109"
              data-name="Group 109"
              transform="translate(0 103.411)"
            >
              <rect
                id="Rectangle_86-11"
                data-name="Rectangle 86"
                width="2.886"
                height="2.405"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-11"
                data-name="Rectangle 87"
                width="2.886"
                height="2.405"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-11"
                data-name="Rectangle 88"
                width="2.886"
                height="2.405"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-11"
                data-name="Rectangle 89"
                width="2.886"
                height="2.405"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-11"
                data-name="Rectangle 90"
                width="2.886"
                height="2.405"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-11"
                data-name="Rectangle 91"
                width="2.886"
                height="2.405"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-11"
                data-name="Rectangle 92"
                width="2.886"
                height="2.405"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-11"
                data-name="Rectangle 93"
                width="2.886"
                height="2.405"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-11"
                data-name="Rectangle 94"
                width="2.886"
                height="2.405"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-11"
                data-name="Rectangle 95"
                width="2.886"
                height="2.405"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-11"
                data-name="Rectangle 96"
                width="2.886"
                height="2.405"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-11"
                data-name="Rectangle 97"
                width="2.886"
                height="2.405"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-11"
                data-name="Rectangle 98"
                width="2.886"
                height="2.405"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-11"
                data-name="Rectangle 99"
                width="2.886"
                height="2.405"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_114"
              data-name="Group 114"
              transform="translate(0 146.218)"
            >
              <rect
                id="Rectangle_86-12"
                data-name="Rectangle 86"
                width="2.886"
                height="2.886"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-12"
                data-name="Rectangle 87"
                width="2.886"
                height="2.886"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-12"
                data-name="Rectangle 88"
                width="2.886"
                height="2.886"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-12"
                data-name="Rectangle 89"
                width="2.886"
                height="2.886"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-12"
                data-name="Rectangle 90"
                width="2.886"
                height="2.886"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-12"
                data-name="Rectangle 91"
                width="2.886"
                height="2.886"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-12"
                data-name="Rectangle 92"
                width="2.886"
                height="2.886"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-12"
                data-name="Rectangle 93"
                width="2.886"
                height="2.886"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-12"
                data-name="Rectangle 94"
                width="2.886"
                height="2.886"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-12"
                data-name="Rectangle 95"
                width="2.886"
                height="2.886"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-12"
                data-name="Rectangle 96"
                width="2.886"
                height="2.886"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-12"
                data-name="Rectangle 97"
                width="2.886"
                height="2.886"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-12"
                data-name="Rectangle 98"
                width="2.886"
                height="2.886"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-12"
                data-name="Rectangle 99"
                width="2.886"
                height="2.886"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_100"
              data-name="Group 100"
              transform="translate(0 25.973)"
            >
              <rect
                id="Rectangle_86-13"
                data-name="Rectangle 86"
                width="2.886"
                height="2.886"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-13"
                data-name="Rectangle 87"
                width="2.886"
                height="2.886"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-13"
                data-name="Rectangle 88"
                width="2.886"
                height="2.886"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-13"
                data-name="Rectangle 89"
                width="2.886"
                height="2.886"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-13"
                data-name="Rectangle 90"
                width="2.886"
                height="2.886"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-13"
                data-name="Rectangle 91"
                width="2.886"
                height="2.886"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-13"
                data-name="Rectangle 92"
                width="2.886"
                height="2.886"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-13"
                data-name="Rectangle 93"
                width="2.886"
                height="2.886"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-13"
                data-name="Rectangle 94"
                width="2.886"
                height="2.886"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-13"
                data-name="Rectangle 95"
                width="2.886"
                height="2.886"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-13"
                data-name="Rectangle 96"
                width="2.886"
                height="2.886"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-13"
                data-name="Rectangle 97"
                width="2.886"
                height="2.886"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-13"
                data-name="Rectangle 98"
                width="2.886"
                height="2.886"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-13"
                data-name="Rectangle 99"
                width="2.886"
                height="2.886"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_103"
              data-name="Group 103"
              transform="translate(0 68.78)"
            >
              <rect
                id="Rectangle_86-14"
                data-name="Rectangle 86"
                width="2.886"
                height="2.886"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-14"
                data-name="Rectangle 87"
                width="2.886"
                height="2.886"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-14"
                data-name="Rectangle 88"
                width="2.886"
                height="2.886"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-14"
                data-name="Rectangle 89"
                width="2.886"
                height="2.886"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-14"
                data-name="Rectangle 90"
                width="2.886"
                height="2.886"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-14"
                data-name="Rectangle 91"
                width="2.886"
                height="2.886"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-14"
                data-name="Rectangle 92"
                width="2.886"
                height="2.886"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-14"
                data-name="Rectangle 93"
                width="2.886"
                height="2.886"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-14"
                data-name="Rectangle 94"
                width="2.886"
                height="2.886"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-14"
                data-name="Rectangle 95"
                width="2.886"
                height="2.886"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-14"
                data-name="Rectangle 96"
                width="2.886"
                height="2.886"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-14"
                data-name="Rectangle 97"
                width="2.886"
                height="2.886"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-14"
                data-name="Rectangle 98"
                width="2.886"
                height="2.886"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-14"
                data-name="Rectangle 99"
                width="2.886"
                height="2.886"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_108"
              data-name="Group 108"
              transform="translate(0 112.068)"
            >
              <rect
                id="Rectangle_86-15"
                data-name="Rectangle 86"
                width="2.886"
                height="2.405"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-15"
                data-name="Rectangle 87"
                width="2.886"
                height="2.405"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-15"
                data-name="Rectangle 88"
                width="2.886"
                height="2.405"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-15"
                data-name="Rectangle 89"
                width="2.886"
                height="2.405"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-15"
                data-name="Rectangle 90"
                width="2.886"
                height="2.405"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-15"
                data-name="Rectangle 91"
                width="2.886"
                height="2.405"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-15"
                data-name="Rectangle 92"
                width="2.886"
                height="2.405"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-15"
                data-name="Rectangle 93"
                width="2.886"
                height="2.405"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-15"
                data-name="Rectangle 94"
                width="2.886"
                height="2.405"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-15"
                data-name="Rectangle 95"
                width="2.886"
                height="2.405"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-15"
                data-name="Rectangle 96"
                width="2.886"
                height="2.405"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-15"
                data-name="Rectangle 97"
                width="2.886"
                height="2.405"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-15"
                data-name="Rectangle 98"
                width="2.886"
                height="2.405"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-15"
                data-name="Rectangle 99"
                width="2.886"
                height="2.405"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_113"
              data-name="Group 113"
              transform="translate(0 155.357)"
            >
              <rect
                id="Rectangle_86-16"
                data-name="Rectangle 86"
                width="2.886"
                height="2.405"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-16"
                data-name="Rectangle 87"
                width="2.886"
                height="2.405"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-16"
                data-name="Rectangle 88"
                width="2.886"
                height="2.405"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-16"
                data-name="Rectangle 89"
                width="2.886"
                height="2.405"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-16"
                data-name="Rectangle 90"
                width="2.886"
                height="2.405"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-16"
                data-name="Rectangle 91"
                width="2.886"
                height="2.405"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-16"
                data-name="Rectangle 92"
                width="2.886"
                height="2.405"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-16"
                data-name="Rectangle 93"
                width="2.886"
                height="2.405"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-16"
                data-name="Rectangle 94"
                width="2.886"
                height="2.405"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-16"
                data-name="Rectangle 95"
                width="2.886"
                height="2.405"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-16"
                data-name="Rectangle 96"
                width="2.886"
                height="2.405"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-16"
                data-name="Rectangle 97"
                width="2.886"
                height="2.405"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-16"
                data-name="Rectangle 98"
                width="2.886"
                height="2.405"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-16"
                data-name="Rectangle 99"
                width="2.886"
                height="2.405"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_101"
              data-name="Group 101"
              transform="translate(0 34.631)"
            >
              <rect
                id="Rectangle_86-17"
                data-name="Rectangle 86"
                width="2.886"
                height="2.405"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-17"
                data-name="Rectangle 87"
                width="2.886"
                height="2.405"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-17"
                data-name="Rectangle 88"
                width="2.886"
                height="2.405"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-17"
                data-name="Rectangle 89"
                width="2.886"
                height="2.405"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-17"
                data-name="Rectangle 90"
                width="2.886"
                height="2.405"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-17"
                data-name="Rectangle 91"
                width="2.886"
                height="2.405"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-17"
                data-name="Rectangle 92"
                width="2.886"
                height="2.405"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-17"
                data-name="Rectangle 93"
                width="2.886"
                height="2.405"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-17"
                data-name="Rectangle 94"
                width="2.886"
                height="2.405"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-17"
                data-name="Rectangle 95"
                width="2.886"
                height="2.405"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-17"
                data-name="Rectangle 96"
                width="2.886"
                height="2.405"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-17"
                data-name="Rectangle 97"
                width="2.886"
                height="2.405"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-17"
                data-name="Rectangle 98"
                width="2.886"
                height="2.405"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-17"
                data-name="Rectangle 99"
                width="2.886"
                height="2.405"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_102"
              data-name="Group 102"
              transform="translate(0 77.919)"
            >
              <rect
                id="Rectangle_86-18"
                data-name="Rectangle 86"
                width="2.886"
                height="2.405"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-18"
                data-name="Rectangle 87"
                width="2.886"
                height="2.405"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-18"
                data-name="Rectangle 88"
                width="2.886"
                height="2.405"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-18"
                data-name="Rectangle 89"
                width="2.886"
                height="2.405"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-18"
                data-name="Rectangle 90"
                width="2.886"
                height="2.405"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-18"
                data-name="Rectangle 91"
                width="2.886"
                height="2.405"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-18"
                data-name="Rectangle 92"
                width="2.886"
                height="2.405"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-18"
                data-name="Rectangle 93"
                width="2.886"
                height="2.405"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-18"
                data-name="Rectangle 94"
                width="2.886"
                height="2.405"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-18"
                data-name="Rectangle 95"
                width="2.886"
                height="2.405"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-18"
                data-name="Rectangle 96"
                width="2.886"
                height="2.405"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-18"
                data-name="Rectangle 97"
                width="2.886"
                height="2.405"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-18"
                data-name="Rectangle 98"
                width="2.886"
                height="2.405"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-18"
                data-name="Rectangle 99"
                width="2.886"
                height="2.405"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_107"
              data-name="Group 107"
              transform="translate(0 120.726)"
            >
              <rect
                id="Rectangle_86-19"
                data-name="Rectangle 86"
                width="2.886"
                height="2.886"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-19"
                data-name="Rectangle 87"
                width="2.886"
                height="2.886"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-19"
                data-name="Rectangle 88"
                width="2.886"
                height="2.886"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-19"
                data-name="Rectangle 89"
                width="2.886"
                height="2.886"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-19"
                data-name="Rectangle 90"
                width="2.886"
                height="2.886"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-19"
                data-name="Rectangle 91"
                width="2.886"
                height="2.886"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-19"
                data-name="Rectangle 92"
                width="2.886"
                height="2.886"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-19"
                data-name="Rectangle 93"
                width="2.886"
                height="2.886"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-19"
                data-name="Rectangle 94"
                width="2.886"
                height="2.886"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-19"
                data-name="Rectangle 95"
                width="2.886"
                height="2.886"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-19"
                data-name="Rectangle 96"
                width="2.886"
                height="2.886"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-19"
                data-name="Rectangle 97"
                width="2.886"
                height="2.886"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-19"
                data-name="Rectangle 98"
                width="2.886"
                height="2.886"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-19"
                data-name="Rectangle 99"
                width="2.886"
                height="2.886"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_112"
              data-name="Group 112"
              transform="translate(0 163.533)"
            >
              <rect
                id="Rectangle_86-20"
                data-name="Rectangle 86"
                width="2.886"
                height="2.886"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-20"
                data-name="Rectangle 87"
                width="2.886"
                height="2.886"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-20"
                data-name="Rectangle 88"
                width="2.886"
                height="2.886"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-20"
                data-name="Rectangle 89"
                width="2.886"
                height="2.886"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-20"
                data-name="Rectangle 90"
                width="2.886"
                height="2.886"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-20"
                data-name="Rectangle 91"
                width="2.886"
                height="2.886"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-20"
                data-name="Rectangle 92"
                width="2.886"
                height="2.886"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-20"
                data-name="Rectangle 93"
                width="2.886"
                height="2.886"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-20"
                data-name="Rectangle 94"
                width="2.886"
                height="2.886"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-20"
                data-name="Rectangle 95"
                width="2.886"
                height="2.886"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-20"
                data-name="Rectangle 96"
                width="2.886"
                height="2.886"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-20"
                data-name="Rectangle 97"
                width="2.886"
                height="2.886"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-20"
                data-name="Rectangle 98"
                width="2.886"
                height="2.886"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-20"
                data-name="Rectangle 99"
                width="2.886"
                height="2.886"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
          </g>
          <g
            id="Group_301"
            data-name="Group 301"
            transform="translate(234.554 1138.052)"
          >
            <g id="Group_97-2" data-name="Group 97" transform="translate(0 0)">
              <rect
                id="Rectangle_86-21"
                data-name="Rectangle 86"
                width="2.886"
                height="2.886"
                transform="translate(137.56)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-21"
                data-name="Rectangle 87"
                width="2.886"
                height="2.886"
                transform="translate(126.979)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-21"
                data-name="Rectangle 88"
                width="2.886"
                height="2.886"
                transform="translate(116.397)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-21"
                data-name="Rectangle 89"
                width="2.886"
                height="2.886"
                transform="translate(105.816)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-21"
                data-name="Rectangle 90"
                width="2.886"
                height="2.886"
                transform="translate(95.234)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-21"
                data-name="Rectangle 91"
                width="2.886"
                height="2.886"
                transform="translate(84.653)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-21"
                data-name="Rectangle 92"
                width="2.886"
                height="2.886"
                transform="translate(74.071)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-21"
                data-name="Rectangle 93"
                width="2.886"
                height="2.886"
                transform="translate(63.489)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-21"
                data-name="Rectangle 94"
                width="2.886"
                height="2.886"
                transform="translate(53.389)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-21"
                data-name="Rectangle 95"
                width="2.886"
                height="2.886"
                transform="translate(42.326)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-21"
                data-name="Rectangle 96"
                width="2.886"
                height="2.886"
                transform="translate(31.745)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-21"
                data-name="Rectangle 97"
                width="2.886"
                height="2.886"
                transform="translate(21.163)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-21"
                data-name="Rectangle 98"
                width="2.886"
                height="2.886"
                transform="translate(10.582)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-21"
                data-name="Rectangle 99"
                width="2.886"
                height="2.886"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_106-2"
              data-name="Group 106"
              transform="translate(0 42.807)"
            >
              <rect
                id="Rectangle_86-22"
                data-name="Rectangle 86"
                width="2.886"
                height="2.886"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-22"
                data-name="Rectangle 87"
                width="2.886"
                height="2.886"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-22"
                data-name="Rectangle 88"
                width="2.886"
                height="2.886"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-22"
                data-name="Rectangle 89"
                width="2.886"
                height="2.886"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-22"
                data-name="Rectangle 90"
                width="2.886"
                height="2.886"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-22"
                data-name="Rectangle 91"
                width="2.886"
                height="2.886"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-22"
                data-name="Rectangle 92"
                width="2.886"
                height="2.886"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-22"
                data-name="Rectangle 93"
                width="2.886"
                height="2.886"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-22"
                data-name="Rectangle 94"
                width="2.886"
                height="2.886"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-22"
                data-name="Rectangle 95"
                width="2.886"
                height="2.886"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-22"
                data-name="Rectangle 96"
                width="2.886"
                height="2.886"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-22"
                data-name="Rectangle 97"
                width="2.886"
                height="2.886"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-22"
                data-name="Rectangle 98"
                width="2.886"
                height="2.886"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-22"
                data-name="Rectangle 99"
                width="2.886"
                height="2.886"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_111-2"
              data-name="Group 111"
              transform="translate(0 86.095)"
            >
              <rect
                id="Rectangle_86-23"
                data-name="Rectangle 86"
                width="2.886"
                height="2.405"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-23"
                data-name="Rectangle 87"
                width="2.886"
                height="2.405"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-23"
                data-name="Rectangle 88"
                width="2.886"
                height="2.405"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-23"
                data-name="Rectangle 89"
                width="2.886"
                height="2.405"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-23"
                data-name="Rectangle 90"
                width="2.886"
                height="2.405"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-23"
                data-name="Rectangle 91"
                width="2.886"
                height="2.405"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-23"
                data-name="Rectangle 92"
                width="2.886"
                height="2.405"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-23"
                data-name="Rectangle 93"
                width="2.886"
                height="2.405"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-23"
                data-name="Rectangle 94"
                width="2.886"
                height="2.405"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-23"
                data-name="Rectangle 95"
                width="2.886"
                height="2.405"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-23"
                data-name="Rectangle 96"
                width="2.886"
                height="2.405"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-23"
                data-name="Rectangle 97"
                width="2.886"
                height="2.405"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-23"
                data-name="Rectangle 98"
                width="2.886"
                height="2.405"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-23"
                data-name="Rectangle 99"
                width="2.886"
                height="2.405"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_116-2"
              data-name="Group 116"
              transform="translate(0 129.384)"
            >
              <rect
                id="Rectangle_86-24"
                data-name="Rectangle 86"
                width="2.886"
                height="2.405"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-24"
                data-name="Rectangle 87"
                width="2.886"
                height="2.405"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-24"
                data-name="Rectangle 88"
                width="2.886"
                height="2.405"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-24"
                data-name="Rectangle 89"
                width="2.886"
                height="2.405"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-24"
                data-name="Rectangle 90"
                width="2.886"
                height="2.405"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-24"
                data-name="Rectangle 91"
                width="2.886"
                height="2.405"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-24"
                data-name="Rectangle 92"
                width="2.886"
                height="2.405"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-24"
                data-name="Rectangle 93"
                width="2.886"
                height="2.405"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-24"
                data-name="Rectangle 94"
                width="2.886"
                height="2.405"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-24"
                data-name="Rectangle 95"
                width="2.886"
                height="2.405"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-24"
                data-name="Rectangle 96"
                width="2.886"
                height="2.405"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-24"
                data-name="Rectangle 97"
                width="2.886"
                height="2.405"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-24"
                data-name="Rectangle 98"
                width="2.886"
                height="2.405"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-24"
                data-name="Rectangle 99"
                width="2.886"
                height="2.405"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_98-2"
              data-name="Group 98"
              transform="translate(0 8.658)"
            >
              <rect
                id="Rectangle_86-25"
                data-name="Rectangle 86"
                width="2.886"
                height="2.405"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-25"
                data-name="Rectangle 87"
                width="2.886"
                height="2.405"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-25"
                data-name="Rectangle 88"
                width="2.886"
                height="2.405"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-25"
                data-name="Rectangle 89"
                width="2.886"
                height="2.405"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-25"
                data-name="Rectangle 90"
                width="2.886"
                height="2.405"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-25"
                data-name="Rectangle 91"
                width="2.886"
                height="2.405"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-25"
                data-name="Rectangle 92"
                width="2.886"
                height="2.405"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-25"
                data-name="Rectangle 93"
                width="2.886"
                height="2.405"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-25"
                data-name="Rectangle 94"
                width="2.886"
                height="2.405"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-25"
                data-name="Rectangle 95"
                width="2.886"
                height="2.405"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-25"
                data-name="Rectangle 96"
                width="2.886"
                height="2.405"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-25"
                data-name="Rectangle 97"
                width="2.886"
                height="2.405"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-25"
                data-name="Rectangle 98"
                width="2.886"
                height="2.405"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-25"
                data-name="Rectangle 99"
                width="2.886"
                height="2.405"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_105-2"
              data-name="Group 105"
              transform="translate(0 51.946)"
            >
              <rect
                id="Rectangle_86-26"
                data-name="Rectangle 86"
                width="2.886"
                height="2.405"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-26"
                data-name="Rectangle 87"
                width="2.886"
                height="2.405"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-26"
                data-name="Rectangle 88"
                width="2.886"
                height="2.405"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-26"
                data-name="Rectangle 89"
                width="2.886"
                height="2.405"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-26"
                data-name="Rectangle 90"
                width="2.886"
                height="2.405"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-26"
                data-name="Rectangle 91"
                width="2.886"
                height="2.405"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-26"
                data-name="Rectangle 92"
                width="2.886"
                height="2.405"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-26"
                data-name="Rectangle 93"
                width="2.886"
                height="2.405"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-26"
                data-name="Rectangle 94"
                width="2.886"
                height="2.405"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-26"
                data-name="Rectangle 95"
                width="2.886"
                height="2.405"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-26"
                data-name="Rectangle 96"
                width="2.886"
                height="2.405"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-26"
                data-name="Rectangle 97"
                width="2.886"
                height="2.405"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-26"
                data-name="Rectangle 98"
                width="2.886"
                height="2.405"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-26"
                data-name="Rectangle 99"
                width="2.886"
                height="2.405"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_110-2"
              data-name="Group 110"
              transform="translate(0 94.753)"
            >
              <rect
                id="Rectangle_86-27"
                data-name="Rectangle 86"
                width="2.886"
                height="2.886"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-27"
                data-name="Rectangle 87"
                width="2.886"
                height="2.886"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-27"
                data-name="Rectangle 88"
                width="2.886"
                height="2.886"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-27"
                data-name="Rectangle 89"
                width="2.886"
                height="2.886"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-27"
                data-name="Rectangle 90"
                width="2.886"
                height="2.886"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-27"
                data-name="Rectangle 91"
                width="2.886"
                height="2.886"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-27"
                data-name="Rectangle 92"
                width="2.886"
                height="2.886"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-27"
                data-name="Rectangle 93"
                width="2.886"
                height="2.886"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-27"
                data-name="Rectangle 94"
                width="2.886"
                height="2.886"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-27"
                data-name="Rectangle 95"
                width="2.886"
                height="2.886"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-27"
                data-name="Rectangle 96"
                width="2.886"
                height="2.886"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-27"
                data-name="Rectangle 97"
                width="2.886"
                height="2.886"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-27"
                data-name="Rectangle 98"
                width="2.886"
                height="2.886"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-27"
                data-name="Rectangle 99"
                width="2.886"
                height="2.886"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_115-2"
              data-name="Group 115"
              transform="translate(0 137.56)"
            >
              <rect
                id="Rectangle_86-28"
                data-name="Rectangle 86"
                width="2.886"
                height="2.886"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-28"
                data-name="Rectangle 87"
                width="2.886"
                height="2.886"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-28"
                data-name="Rectangle 88"
                width="2.886"
                height="2.886"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-28"
                data-name="Rectangle 89"
                width="2.886"
                height="2.886"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-28"
                data-name="Rectangle 90"
                width="2.886"
                height="2.886"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-28"
                data-name="Rectangle 91"
                width="2.886"
                height="2.886"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-28"
                data-name="Rectangle 92"
                width="2.886"
                height="2.886"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-28"
                data-name="Rectangle 93"
                width="2.886"
                height="2.886"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-28"
                data-name="Rectangle 94"
                width="2.886"
                height="2.886"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-28"
                data-name="Rectangle 95"
                width="2.886"
                height="2.886"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-28"
                data-name="Rectangle 96"
                width="2.886"
                height="2.886"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-28"
                data-name="Rectangle 97"
                width="2.886"
                height="2.886"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-28"
                data-name="Rectangle 98"
                width="2.886"
                height="2.886"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-28"
                data-name="Rectangle 99"
                width="2.886"
                height="2.886"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_99-2"
              data-name="Group 99"
              transform="translate(0 17.315)"
            >
              <rect
                id="Rectangle_86-29"
                data-name="Rectangle 86"
                width="2.886"
                height="2.886"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-29"
                data-name="Rectangle 87"
                width="2.886"
                height="2.886"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-29"
                data-name="Rectangle 88"
                width="2.886"
                height="2.886"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-29"
                data-name="Rectangle 89"
                width="2.886"
                height="2.886"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-29"
                data-name="Rectangle 90"
                width="2.886"
                height="2.886"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-29"
                data-name="Rectangle 91"
                width="2.886"
                height="2.886"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-29"
                data-name="Rectangle 92"
                width="2.886"
                height="2.886"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-29"
                data-name="Rectangle 93"
                width="2.886"
                height="2.886"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-29"
                data-name="Rectangle 94"
                width="2.886"
                height="2.886"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-29"
                data-name="Rectangle 95"
                width="2.886"
                height="2.886"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-29"
                data-name="Rectangle 96"
                width="2.886"
                height="2.886"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-29"
                data-name="Rectangle 97"
                width="2.886"
                height="2.886"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-29"
                data-name="Rectangle 98"
                width="2.886"
                height="2.886"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-29"
                data-name="Rectangle 99"
                width="2.886"
                height="2.886"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_104-2"
              data-name="Group 104"
              transform="translate(0 60.604)"
            >
              <rect
                id="Rectangle_86-30"
                data-name="Rectangle 86"
                width="2.886"
                height="2.405"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-30"
                data-name="Rectangle 87"
                width="2.886"
                height="2.405"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-30"
                data-name="Rectangle 88"
                width="2.886"
                height="2.405"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-30"
                data-name="Rectangle 89"
                width="2.886"
                height="2.405"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-30"
                data-name="Rectangle 90"
                width="2.886"
                height="2.405"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-30"
                data-name="Rectangle 91"
                width="2.886"
                height="2.405"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-30"
                data-name="Rectangle 92"
                width="2.886"
                height="2.405"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-30"
                data-name="Rectangle 93"
                width="2.886"
                height="2.405"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-30"
                data-name="Rectangle 94"
                width="2.886"
                height="2.405"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-30"
                data-name="Rectangle 95"
                width="2.886"
                height="2.405"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-30"
                data-name="Rectangle 96"
                width="2.886"
                height="2.405"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-30"
                data-name="Rectangle 97"
                width="2.886"
                height="2.405"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-30"
                data-name="Rectangle 98"
                width="2.886"
                height="2.405"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-30"
                data-name="Rectangle 99"
                width="2.886"
                height="2.405"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_109-2"
              data-name="Group 109"
              transform="translate(0 103.411)"
            >
              <rect
                id="Rectangle_86-31"
                data-name="Rectangle 86"
                width="2.886"
                height="2.405"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-31"
                data-name="Rectangle 87"
                width="2.886"
                height="2.405"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-31"
                data-name="Rectangle 88"
                width="2.886"
                height="2.405"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-31"
                data-name="Rectangle 89"
                width="2.886"
                height="2.405"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-31"
                data-name="Rectangle 90"
                width="2.886"
                height="2.405"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-31"
                data-name="Rectangle 91"
                width="2.886"
                height="2.405"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-31"
                data-name="Rectangle 92"
                width="2.886"
                height="2.405"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-31"
                data-name="Rectangle 93"
                width="2.886"
                height="2.405"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-31"
                data-name="Rectangle 94"
                width="2.886"
                height="2.405"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-31"
                data-name="Rectangle 95"
                width="2.886"
                height="2.405"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-31"
                data-name="Rectangle 96"
                width="2.886"
                height="2.405"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-31"
                data-name="Rectangle 97"
                width="2.886"
                height="2.405"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-31"
                data-name="Rectangle 98"
                width="2.886"
                height="2.405"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-31"
                data-name="Rectangle 99"
                width="2.886"
                height="2.405"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_114-2"
              data-name="Group 114"
              transform="translate(0 146.218)"
            >
              <rect
                id="Rectangle_86-32"
                data-name="Rectangle 86"
                width="2.886"
                height="2.886"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-32"
                data-name="Rectangle 87"
                width="2.886"
                height="2.886"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-32"
                data-name="Rectangle 88"
                width="2.886"
                height="2.886"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-32"
                data-name="Rectangle 89"
                width="2.886"
                height="2.886"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-32"
                data-name="Rectangle 90"
                width="2.886"
                height="2.886"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-32"
                data-name="Rectangle 91"
                width="2.886"
                height="2.886"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-32"
                data-name="Rectangle 92"
                width="2.886"
                height="2.886"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-32"
                data-name="Rectangle 93"
                width="2.886"
                height="2.886"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-32"
                data-name="Rectangle 94"
                width="2.886"
                height="2.886"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-32"
                data-name="Rectangle 95"
                width="2.886"
                height="2.886"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-32"
                data-name="Rectangle 96"
                width="2.886"
                height="2.886"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-32"
                data-name="Rectangle 97"
                width="2.886"
                height="2.886"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-32"
                data-name="Rectangle 98"
                width="2.886"
                height="2.886"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-32"
                data-name="Rectangle 99"
                width="2.886"
                height="2.886"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_100-2"
              data-name="Group 100"
              transform="translate(0 25.973)"
            >
              <rect
                id="Rectangle_86-33"
                data-name="Rectangle 86"
                width="2.886"
                height="2.886"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-33"
                data-name="Rectangle 87"
                width="2.886"
                height="2.886"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-33"
                data-name="Rectangle 88"
                width="2.886"
                height="2.886"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-33"
                data-name="Rectangle 89"
                width="2.886"
                height="2.886"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-33"
                data-name="Rectangle 90"
                width="2.886"
                height="2.886"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-33"
                data-name="Rectangle 91"
                width="2.886"
                height="2.886"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-33"
                data-name="Rectangle 92"
                width="2.886"
                height="2.886"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-33"
                data-name="Rectangle 93"
                width="2.886"
                height="2.886"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-33"
                data-name="Rectangle 94"
                width="2.886"
                height="2.886"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-33"
                data-name="Rectangle 95"
                width="2.886"
                height="2.886"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-33"
                data-name="Rectangle 96"
                width="2.886"
                height="2.886"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-33"
                data-name="Rectangle 97"
                width="2.886"
                height="2.886"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-33"
                data-name="Rectangle 98"
                width="2.886"
                height="2.886"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-33"
                data-name="Rectangle 99"
                width="2.886"
                height="2.886"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_103-2"
              data-name="Group 103"
              transform="translate(0 68.78)"
            >
              <rect
                id="Rectangle_86-34"
                data-name="Rectangle 86"
                width="2.886"
                height="2.886"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-34"
                data-name="Rectangle 87"
                width="2.886"
                height="2.886"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-34"
                data-name="Rectangle 88"
                width="2.886"
                height="2.886"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-34"
                data-name="Rectangle 89"
                width="2.886"
                height="2.886"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-34"
                data-name="Rectangle 90"
                width="2.886"
                height="2.886"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-34"
                data-name="Rectangle 91"
                width="2.886"
                height="2.886"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-34"
                data-name="Rectangle 92"
                width="2.886"
                height="2.886"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-34"
                data-name="Rectangle 93"
                width="2.886"
                height="2.886"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-34"
                data-name="Rectangle 94"
                width="2.886"
                height="2.886"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-34"
                data-name="Rectangle 95"
                width="2.886"
                height="2.886"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-34"
                data-name="Rectangle 96"
                width="2.886"
                height="2.886"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-34"
                data-name="Rectangle 97"
                width="2.886"
                height="2.886"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-34"
                data-name="Rectangle 98"
                width="2.886"
                height="2.886"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-34"
                data-name="Rectangle 99"
                width="2.886"
                height="2.886"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_108-2"
              data-name="Group 108"
              transform="translate(0 112.068)"
            >
              <rect
                id="Rectangle_86-35"
                data-name="Rectangle 86"
                width="2.886"
                height="2.405"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-35"
                data-name="Rectangle 87"
                width="2.886"
                height="2.405"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-35"
                data-name="Rectangle 88"
                width="2.886"
                height="2.405"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-35"
                data-name="Rectangle 89"
                width="2.886"
                height="2.405"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-35"
                data-name="Rectangle 90"
                width="2.886"
                height="2.405"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-35"
                data-name="Rectangle 91"
                width="2.886"
                height="2.405"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-35"
                data-name="Rectangle 92"
                width="2.886"
                height="2.405"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-35"
                data-name="Rectangle 93"
                width="2.886"
                height="2.405"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-35"
                data-name="Rectangle 94"
                width="2.886"
                height="2.405"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-35"
                data-name="Rectangle 95"
                width="2.886"
                height="2.405"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-35"
                data-name="Rectangle 96"
                width="2.886"
                height="2.405"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-35"
                data-name="Rectangle 97"
                width="2.886"
                height="2.405"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-35"
                data-name="Rectangle 98"
                width="2.886"
                height="2.405"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-35"
                data-name="Rectangle 99"
                width="2.886"
                height="2.405"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_113-2"
              data-name="Group 113"
              transform="translate(0 155.357)"
            >
              <rect
                id="Rectangle_86-36"
                data-name="Rectangle 86"
                width="2.886"
                height="2.405"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-36"
                data-name="Rectangle 87"
                width="2.886"
                height="2.405"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-36"
                data-name="Rectangle 88"
                width="2.886"
                height="2.405"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-36"
                data-name="Rectangle 89"
                width="2.886"
                height="2.405"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-36"
                data-name="Rectangle 90"
                width="2.886"
                height="2.405"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-36"
                data-name="Rectangle 91"
                width="2.886"
                height="2.405"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-36"
                data-name="Rectangle 92"
                width="2.886"
                height="2.405"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-36"
                data-name="Rectangle 93"
                width="2.886"
                height="2.405"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-36"
                data-name="Rectangle 94"
                width="2.886"
                height="2.405"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-36"
                data-name="Rectangle 95"
                width="2.886"
                height="2.405"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-36"
                data-name="Rectangle 96"
                width="2.886"
                height="2.405"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-36"
                data-name="Rectangle 97"
                width="2.886"
                height="2.405"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-36"
                data-name="Rectangle 98"
                width="2.886"
                height="2.405"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-36"
                data-name="Rectangle 99"
                width="2.886"
                height="2.405"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_101-2"
              data-name="Group 101"
              transform="translate(0 34.631)"
            >
              <rect
                id="Rectangle_86-37"
                data-name="Rectangle 86"
                width="2.886"
                height="2.405"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-37"
                data-name="Rectangle 87"
                width="2.886"
                height="2.405"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-37"
                data-name="Rectangle 88"
                width="2.886"
                height="2.405"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-37"
                data-name="Rectangle 89"
                width="2.886"
                height="2.405"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-37"
                data-name="Rectangle 90"
                width="2.886"
                height="2.405"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-37"
                data-name="Rectangle 91"
                width="2.886"
                height="2.405"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-37"
                data-name="Rectangle 92"
                width="2.886"
                height="2.405"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-37"
                data-name="Rectangle 93"
                width="2.886"
                height="2.405"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-37"
                data-name="Rectangle 94"
                width="2.886"
                height="2.405"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-37"
                data-name="Rectangle 95"
                width="2.886"
                height="2.405"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-37"
                data-name="Rectangle 96"
                width="2.886"
                height="2.405"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-37"
                data-name="Rectangle 97"
                width="2.886"
                height="2.405"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-37"
                data-name="Rectangle 98"
                width="2.886"
                height="2.405"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-37"
                data-name="Rectangle 99"
                width="2.886"
                height="2.405"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_102-2"
              data-name="Group 102"
              transform="translate(0 77.919)"
            >
              <rect
                id="Rectangle_86-38"
                data-name="Rectangle 86"
                width="2.886"
                height="2.405"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-38"
                data-name="Rectangle 87"
                width="2.886"
                height="2.405"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-38"
                data-name="Rectangle 88"
                width="2.886"
                height="2.405"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-38"
                data-name="Rectangle 89"
                width="2.886"
                height="2.405"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-38"
                data-name="Rectangle 90"
                width="2.886"
                height="2.405"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-38"
                data-name="Rectangle 91"
                width="2.886"
                height="2.405"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-38"
                data-name="Rectangle 92"
                width="2.886"
                height="2.405"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-38"
                data-name="Rectangle 93"
                width="2.886"
                height="2.405"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-38"
                data-name="Rectangle 94"
                width="2.886"
                height="2.405"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-38"
                data-name="Rectangle 95"
                width="2.886"
                height="2.405"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-38"
                data-name="Rectangle 96"
                width="2.886"
                height="2.405"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-38"
                data-name="Rectangle 97"
                width="2.886"
                height="2.405"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-38"
                data-name="Rectangle 98"
                width="2.886"
                height="2.405"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-38"
                data-name="Rectangle 99"
                width="2.886"
                height="2.405"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_107-2"
              data-name="Group 107"
              transform="translate(0 120.726)"
            >
              <rect
                id="Rectangle_86-39"
                data-name="Rectangle 86"
                width="2.886"
                height="2.886"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-39"
                data-name="Rectangle 87"
                width="2.886"
                height="2.886"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-39"
                data-name="Rectangle 88"
                width="2.886"
                height="2.886"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-39"
                data-name="Rectangle 89"
                width="2.886"
                height="2.886"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-39"
                data-name="Rectangle 90"
                width="2.886"
                height="2.886"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-39"
                data-name="Rectangle 91"
                width="2.886"
                height="2.886"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-39"
                data-name="Rectangle 92"
                width="2.886"
                height="2.886"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-39"
                data-name="Rectangle 93"
                width="2.886"
                height="2.886"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-39"
                data-name="Rectangle 94"
                width="2.886"
                height="2.886"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-39"
                data-name="Rectangle 95"
                width="2.886"
                height="2.886"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-39"
                data-name="Rectangle 96"
                width="2.886"
                height="2.886"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-39"
                data-name="Rectangle 97"
                width="2.886"
                height="2.886"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-39"
                data-name="Rectangle 98"
                width="2.886"
                height="2.886"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-39"
                data-name="Rectangle 99"
                width="2.886"
                height="2.886"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
            <g
              id="Group_112-2"
              data-name="Group 112"
              transform="translate(0 163.533)"
            >
              <rect
                id="Rectangle_86-40"
                data-name="Rectangle 86"
                width="2.886"
                height="2.886"
                transform="translate(137.56 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_87-40"
                data-name="Rectangle 87"
                width="2.886"
                height="2.886"
                transform="translate(126.979 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_88-40"
                data-name="Rectangle 88"
                width="2.886"
                height="2.886"
                transform="translate(116.397 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_89-40"
                data-name="Rectangle 89"
                width="2.886"
                height="2.886"
                transform="translate(105.816 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_90-40"
                data-name="Rectangle 90"
                width="2.886"
                height="2.886"
                transform="translate(95.234 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_91-40"
                data-name="Rectangle 91"
                width="2.886"
                height="2.886"
                transform="translate(84.653 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_92-40"
                data-name="Rectangle 92"
                width="2.886"
                height="2.886"
                transform="translate(74.071 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_93-40"
                data-name="Rectangle 93"
                width="2.886"
                height="2.886"
                transform="translate(63.489 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_94-40"
                data-name="Rectangle 94"
                width="2.886"
                height="2.886"
                transform="translate(53.389 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_95-40"
                data-name="Rectangle 95"
                width="2.886"
                height="2.886"
                transform="translate(42.326 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_96-40"
                data-name="Rectangle 96"
                width="2.886"
                height="2.886"
                transform="translate(31.745 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_97-40"
                data-name="Rectangle 97"
                width="2.886"
                height="2.886"
                transform="translate(21.163 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_98-40"
                data-name="Rectangle 98"
                width="2.886"
                height="2.886"
                transform="translate(10.582 0)"
                fill="#a14c9d"
              />
              <rect
                id="Rectangle_99-40"
                data-name="Rectangle 99"
                width="2.886"
                height="2.886"
                transform="translate(0 0)"
                fill="#a14c9d"
              />
            </g>
          </g>
        </g>
        <rect
          id="home-slider-2"
          width="340.534"
          height="363.14"
          transform="translate(-0.165 1166.43)"
          fill="url(#pattern)"
        />
      </g>
      <g transform="translate(5 25)">
        <image href={source} x="0" y="0" width="95%" height="90%" />
      </g>
    </svg>
  );
};

export default DottedRectanglePhotoSVG;
