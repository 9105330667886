import React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaDribbble,
  FaBehance,
  FaShoppingCart,
} from 'react-icons/fa';
import classNames from 'classnames';

import styles from './styles';
import { SocialMediaIcon } from 'Components/SocialMediaIcon';
import { ISocialmedia } from 'Components/types';

export interface Props extends WithStyles<typeof styles> {}

interface IProps {
  classes: Partial<Record<string, string>>;
  socialmedias: ISocialmedia[];
  inline?: boolean;
  className?: string;
}

const SocialMediaIcons = (props: IProps) => {
  const { classes, socialmedias, inline, className } = props;

  return (
    <ul
      className={classNames(classes.socialmediaList, {
        [`${classes.inline}`]: inline,
      })}
    >
      {socialmedias && socialmedias.map((socialmedia) => (
        <SocialMediaIcon
          className={className}
          link={socialmedia.url}
          label={socialmedia.name}
          key={socialmedia.id}
          icon={
            (socialmedia.name === 'facebook' && <FaFacebookF />) ||
            (socialmedia.name === 'twitter' && <FaTwitter />) ||
            (socialmedia.name === 'linkedin' && <FaLinkedinIn />) ||
            (socialmedia.name === 'dribbble' && <FaDribbble />) ||
            (socialmedia.name === 'behance' && <FaBehance />) ||
            (socialmedia.name === 'gumroad' && <FaShoppingCart />)
          }
        />
      ))}
    </ul>
  );
};

export default withStyles(styles)(SocialMediaIcons);
