import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100vh',
    },
    loader: {
      display: 'block',
      top: '45%',
      position: 'relative',
    },
  });

export default styles;
