import React, { useContext } from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Button, Grid } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import axios from 'axios';

import styles from './styles';
import { Section } from 'Components/Section';
import { Title } from 'Components/Title';
import { heading } from 'Components/ContactSection';
import { AuthContext } from 'Context/AuthContext';
import { mergeArrays, minText, maxText, invalidText } from 'utils';
import { IAuth, IEmail } from 'Components/types';

export interface Props extends WithStyles<typeof styles> {}

interface IProps {
  classes: Partial<Record<string, string>>;
}

const ContactSection = (props: IProps) => {
  const { classes } = props;
  const RequiredText = 'Required';
  const authState = useContext(AuthContext);
  const auth: IAuth[] = mergeArrays(authState);
  const accessToken = auth[0] && auth[0].jwt;

  const initialValues = {
    name: '',
    email: '',
    message: '',
  };

  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const sendEmail = (values: IEmail) => {
    let { name, email, message } = values;

    authAxios
      .post('/email-send', {
        from: email,
        subject: `${name} have sent a message from Jeandre Melaria|Portfolio`,
        html: message,
      })
      .catch((err) => console.error(err));
  };

  return (
    <Section id={'Contact'} className={classes.section}>
      <Title title={'Contact'} />
      <Typography variant={'h4'} className={classes.heading}>
        {heading}
      </Typography>

      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          name: Yup.string()
            .min(3, minText(3))
            .max(25, maxText(25))
            .required(RequiredText),
          email: Yup.string()
            .email(invalidText('email'))
            .required(RequiredText),
          message: Yup.string().min(10, minText(10)).required(RequiredText),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setTimeout(() => {
            sendEmail(values);
            setSubmitting(false);
            resetForm({ values: initialValues });
          }, 400);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Field
                  className={classes.field}
                  component={TextField}
                  type="name"
                  name="name"
                  label="Name"
                  placeholder="Name"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  className={classes.field}
                  component={TextField}
                  type="email"
                  name="email"
                  label="Email"
                  placeholder="Email"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  className={classes.field}
                  multiline
                  rowsMax={4}
                  component={TextField}
                  type="message"
                  name="message"
                  placeholder="Message"
                  label="Message"
                  fullWidth
                />
              </Grid>
            </Grid>

            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Sending message...' : 'Send Message'}
            </Button>
          </Form>
        )}
      </Formik>
    </Section>
  );
};

export default withStyles(styles)(ContactSection);
