import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './App.css';
import { Preloader } from 'Components/Preloader';
import HomePage from 'Pages/Home';
import { ProjectPage } from 'Pages';

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return loading ? (
    <Preloader />
  ) : (
    <Router>
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/:id" exact component={ProjectPage} />
      </Switch>
    </Router>
  );
};

export default App;
