import { Theme, createStyles } from '@material-ui/core';

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    section: {
      display: 'flex',
      textAlign: 'justify',
      alignItems: 'center',
      flexDirection: 'column',
      paddingBottom: spacing(8),
    },
    container: {
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
      maxWidth: 1200,
      marginTop: spacing(2),
      display: 'flex',
      justifyContent: 'center',
    },
    heading: {
      marginBottom: spacing(2),
      textAlign: 'center',
    },
    item: {
      display: 'flex',
      justifyContent: 'center',
    },
    chip: {
      backgroundColor: 'transparent',
      color: palette.primary.dark,

      '&:hover, &:focus': {
        color: palette.common.white,
        background: palette.primary.main,
        transition: 'all 0.3s ease-in-out',
      },
    },
    active: {
      color: palette.common.white,
      background: palette.primary.main,
      transition: 'all 0.3s ease-in-out',
    },
    label: {
      paddingLeft: spacing(1),
      paddingRight: spacing(1),
    },
  });

export default styles;
