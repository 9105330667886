import React from 'react';

import { PortfolioSection } from 'Components/PortfolioSection';

class Portfolio extends React.Component {
  public render() {
    return (
      <PortfolioSection />
    );
  }
}

export default Portfolio;
