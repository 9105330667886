import React, { createContext, useEffect, useState, ReactNode } from 'react';
import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

import userDummy from './userDummy.json';

interface IProps {
  children: ReactNode;
}

interface IProfileAPI {
  id: number;
  firstPhotoUrl: string;
  secondPhotoUrl: string;
  firstname: string;
  lastname: string;
  jobTitle: string;
  jobTitleExplanation: string;
  aboutMe: string;
  email: string;
}

interface ISocialmediaAPI {
  id: number;
  name: string;
  url: string;
}

export interface ISkillAPI {
  id: number;
  percentage: number;
  subtitle: string;
  title: string;
}

export interface IProjectAPI {
  id: number;
  title: string;
  description: string;
  categories: string;
  thumbnailUrl: string;
  caseStudy: {
    client: string;
    category: string;
    scope: string;
    projectObjectives: string;
    website: string;
  };
  images: any[];
  tools: {
    adobeXd: boolean;
    figma: boolean;
    adobeIllustrator: boolean;
    adobeDimension: boolean;
  };
  technologies: {
    reactJS: boolean;
    angularJS: boolean;
    typescript: boolean;
    materialUi: boolean;
    git: boolean;
  };
}

export interface IBrandAPI {
  id: number;
  logoUrl: string;
  slogan: string;
  description: string;
}

interface IUserAPI {
  profile: IProfileAPI;
  socialmedias: ISocialmediaAPI[];
  skills: ISkillAPI[];
  projects: IProjectAPI[];
  brand: IBrandAPI;
}

const defaultValue = [] as any[];

export const UserContext = createContext(defaultValue);

const UserProvider = (props: IProps) => {
  const [userState, setStateUser] = useState(defaultValue);
  const mock = new MockAdapter(axios);
  mock.onGet('/user').reply(200, { user: userDummy });

  useEffect(() => {
    axios
      .get(`/user`)
      .then(({ data }) => setStateUser(data))
      .catch((err) => console.error(err));
  }, []);

  return (
    <UserContext.Provider value={[userState as IUserAPI[]]}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserProvider;
