import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#A14C9D',
      dark: '#70356D',
    },
    secondary: {
      main: '#151D41',
      dark: '#66686A',
      light: 'rgba(179, 111, 176, 0.1)',
    },
  },
});
