import React, { KeyboardEvent, MouseEvent } from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
} from '@material-ui/core';
import {
  FaHome,
  FaInfoCircle,
  FaBook,
  FaBlog,
  FaShoppingCart,
  FaMedal,
} from 'react-icons/fa';
import { MdMail } from 'react-icons/md';
import { HashLink } from 'react-router-hash-link';

import styles from './styles';
import { MenuIcon } from 'Components/MenuIcon';
import { NavigationButton } from 'Components/navigationButton';
import { navButtonTitles } from './index';
import { CustomButton } from 'Components/CustomButton';
import { OutlinedTriangleSVG } from 'Components/OutlinedTriangleSVG';
import { Logo } from 'Components/Logo';

export interface Props extends WithStyles<typeof styles> {}

interface IProps {
  classes: Partial<Record<string, string>>;
  redirect?: boolean;
}

type Anchor = 'right';

const Header = (props: IProps) => {
  const { classes, redirect } = props;
  const anchor: Anchor = 'right';
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchorRight: Anchor, open: boolean) =>
    function (event: KeyboardEvent | MouseEvent) {
      if (
        event.type === 'keydown' &&
        ((event as KeyboardEvent).key === 'Tab' ||
          (event as KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchorRight]: open });
    };

  const list = (anchorRight: Anchor) => (
    <div
      role="presentation"
      onClick={toggleDrawer(anchorRight, false)}
      onKeyDown={toggleDrawer(anchorRight, false)}
    >
      <List className={classes.list}>
        {navButtonTitles.map((title) => (
          <HashLink
            className={classes.link}
            key={title}
            to={redirect ? `/#${title}` : `#${title}`}
            scroll={(el: any) =>
              el.scrollIntoView({ behavior: 'smooth', block: 'end' })
            }
          >
            <ListItem
              button
              className={classes.listItem}
              classes={{ selected: classes.selected }}
            >
              <ListItemIcon className={classes.listItemIcon}>
                {title === 'Home' && <FaHome className={classes.icon} />}
                {title === 'About me' && (
                  <FaInfoCircle className={classes.icon} />
                )}
                {title === 'Skills' && <FaMedal className={classes.icon} />}
                {title === 'Portfolio' && <FaBook className={classes.icon} />}
                {title === 'Blog' && <FaBlog className={classes.icon} />}
                {title === 'Shop' && (
                  <FaShoppingCart className={classes.icon} />
                )}
                {title === 'Contact' && <MdMail className={classes.icon} />}
              </ListItemIcon>
              <ListItemText primary={title} />
            </ListItem>
          </HashLink>
        ))}
      </List>
    </div>
  );

  return (
    <AppBar position="static" className={classes.appbar}>
      <OutlinedTriangleSVG className={classes.triangle} />
      <Toolbar>
        <Grid container>
          <Grid className={classes.logoContainer} item xs={6} md={2}>
            <Logo redirect/>
          </Grid>
          <Grid item md={8} className={classes.navigationItem}>
            {navButtonTitles.map((title) => (
              <HashLink
                key={title}
                to={redirect ? `/#${title}` : `#${title}`}
                scroll={(el: any) =>
                  el.scrollIntoView({ behavior: 'smooth', block: 'end' })
                }
              >
                <NavigationButton title={title} />
              </HashLink>
            ))}
            {!redirect && <CustomButton link={'Contact'} title={'Hire Me'} />}
          </Grid>
          <Grid item xs={6} md={'auto'} className={classes.menuContainer}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(anchor, true)}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {list(anchor)}
            </Drawer>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(Header);
