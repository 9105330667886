import React, { useContext } from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { HashLink } from 'react-router-hash-link';

import { IUser } from 'Components/types';
import styles from './styles';
import { UserContext } from 'Context/UserContext';

export interface Props extends WithStyles<typeof styles> {}

interface IProps {
  classes: Partial<Record<string, string>>;
  redirect?: boolean;
}

const Logo = (props: IProps) => {
  const { classes, redirect } = props;
  const userState = useContext(UserContext);
  const user: IUser = userState[0] && userState[0].user;
  const logo = user && user.brand.logoUrl;

  return (
    <HashLink
      className={classes.logoLink}
      to={redirect ? `/#Home` : `#Home`}
      scroll={(el: any) =>
        el.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }
    >
      <img alt="logo" src={logo} className={classes.logo} />
    </HashLink>
  );
};

export default withStyles(styles)(Logo);
