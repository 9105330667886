import React from 'react';

import { AboutMeSection } from 'Components/AboutMeSection';

class AboutMe extends React.Component {
  public render() {
    return (
      <AboutMeSection />
    );
  }
}

export default AboutMe;
