import { Theme, createStyles } from '@material-ui/core';

const styles = ({ transitions, spacing }: Theme) =>
  createStyles({
    card: {
      textAlign: 'start',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto !important',
      transition: transitions.create('transform', {
        duration: transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    tools: {
      marginBottom: spacing(2),
    },
    icons: {
      marginRight: spacing(1),
      marginTop: spacing(1),
    },
    link: {
      textDecoration: 'none',
    },
  });

export default styles;
