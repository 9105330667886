import React, { useContext } from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { HashLink } from 'react-router-hash-link';
import { Button, Grid, Typography } from '@material-ui/core';

import styles from './styles';
import { Section } from 'Components/Section';
import { SocialMediaIcons } from 'Components/SocialMediaIcons';
import { AvatarSVG } from 'Components/AvatarSVG';
import { Title } from 'Components/Title';
import { buttons } from './index';
import { OutlinedTriangleSVG } from 'Components/OutlinedTriangleSVG';
import { UserContext } from 'Context/UserContext';
import { IUser } from 'Components/types';
import { ThinOutlineCircleSVG } from 'Components/ThinOutlineCircleSVG';

export interface Props extends WithStyles<typeof styles> {}

interface IProps {
  classes: Partial<Record<string, string>>;
}

type variantType = 'outlined' | 'contained';

const HomeSection = (props: IProps) => {
  const { classes } = props;
  const userState = useContext(UserContext);
  const user: IUser = userState[0] && userState[0].user;
  const profile = user && user.profile;

  return (
    <Section id={'Home'}>
      <Grid container>
        <Grid item xs={3} md={1} className={classes.socialMediaIconsGridItem}>
          <OutlinedTriangleSVG className={classes.triangle} />
          {user && <SocialMediaIcons socialmedias={user.socialmedias} />}
        </Grid>
        <Grid item xs={9} md={6} className={classes.avatarSVGGridItem}>
          {profile && <AvatarSVG source={profile.firstPhotoUrl} />}
        </Grid>
        <Grid item xs={12} md={5} className={classes.textGridItem}>
          <div className={classes.textContainer}>
            <Title title={`Welcome! I'M`} />
            {profile && (
              <>
                <Typography variant="h4">
                  {`${profile.firstname} ${profile.lastname}`}
                </Typography>
                <Typography variant="subtitle1">
                  {profile.jobTitle}
                </Typography>
                <Typography variant="body2" className={classes.text}>
                  {profile.jobTitleExplanation}
                </Typography>
              </>
            )}

            <Grid container className={classes.buttonContainer} spacing={2}>
              {buttons.map(({ title, variant, link }) => (
                <Grid
                  item
                  key={title}
                  xs={6}
                  sm={2}
                  md={4}
                  lg={3}
                  xl={2}
                  className={classes.buttonItem}
                >
                  <HashLink
                    className={classNames(classes.link, {
                      [`${classes.exploreButton}`]: title === 'Explore',
                    })}
                    key={title}
                    to={'#' + link}
                    scroll={(el: any) =>
                      el.scrollIntoView({ behavior: 'smooth', block: 'end' })
                    }
                  >
                    <Button
                      fullWidth
                      variant={variant as variantType}
                      color="primary"
                      className={classes.button}
                    >
                      {title}
                    </Button>
                  </HashLink>
                </Grid>
              ))}
              <OutlinedTriangleSVG className={classes.secondTriangle} />
              <ThinOutlineCircleSVG className={classes.circle} />
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Section>
  );
};

export default withStyles(styles)(HomeSection);
