import React, { useContext, useState } from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Chip } from '@material-ui/core';
import classNames from 'classnames';

import styles from './styles';
import { Section } from 'Components/Section';
import { Title } from 'Components/Title';
import { heading } from 'Components/PortfolioSection';
import { CustomCard } from 'Components/CustomCard';
import { UserContext } from 'Context/UserContext';
import { IProject, IUser } from 'Components/types';
import { cardButton, chips } from './index';

export interface Props extends WithStyles<typeof styles> {}

interface IProps {
  classes: Partial<Record<string, string>>;
}

const PortfolioSection = (props: IProps) => {
  const { classes } = props;
  const userState = useContext(UserContext);
  const user: IUser = userState[0] && userState[0].user;
  const projects: IProject[] = user && user.projects;

  const defaultStateValue = {
    title: 'All',
    status: true,
  };

  const [categoriesState, setStateCategories] = useState(defaultStateValue);
  const getCategories = (categories: string) =>
    setStateCategories({ title: categories, status: true });

  const filterData = (project: IProject) => {
    return (
      project.categories !== null &&
      project.categories.search(categoriesState.title) > -1 && (
        <Grid key={project.id} item xs={12} md={4}>
          <CustomCard buttons={cardButton} project={project} />
        </Grid>
      )
    );
  };

  return (
    <Section id={'Portfolio'} className={classes.section}>
      <Title title={'Portfolio'} />
      <Typography variant={'h4'} className={classes.heading}>
        {heading}
      </Typography>

      <Grid container>
        <Grid item xs={12} className={classes.item}>
          {chips.map((chip) => (
            <Chip
              component="button"
              onClick={(event: any) =>
                getCategories(event.currentTarget.innerText)
              }
              clickable
              classes={{ label: classes.label }}
              className={classNames(classes.chip, {
                [`${classes.active}`]:
                  chip.title === 'All' &&
                  categoriesState.title === 'All' &&
                  categoriesState.status,
              })}
              key={chip.title}
              label={chip.title}
            />
          ))}
        </Grid>
      </Grid>

      <Grid container spacing={4} className={classes.container}>
        {projects && projects.map((project) => filterData(project))}
      </Grid>
    </Section>
  );
};

export default withStyles(styles)(PortfolioSection);
